import {
  LoadingOutlined,
  CheckCircleOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined,
  CheckSquareOutlined,
  CloseSquareOutlined,
} from '@ant-design/icons'
import { Button, Drawer, Tooltip } from 'antd'
import axios from 'axios'
import moment from 'moment'
import numeral from 'numeral'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import AudienceData from './creator/AudienceData'
import CreatorAvatar from './CreatorAvatar'
import { NODE_URL } from '../../constants'
import instagram from '../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../images/icons/social/youtube-color-square.svg'
import { socialProfileToUrl } from '../../utils'
import { EngagementBreakdown } from '../campaign-dashboard/influencer-profile/EngagementBreakdown'
const socialIcons = { instagram, youtube, tiktok }

const Channels = ({ optIn, creator, campaign }) => {
  // optIn variable used for optIn dependent imports and creator variable for non-optIn dependent imports
  const { user } = optIn || creator
  const optInChannels = optIn?.extraData?.channels
  const campaignChannels = campaign?.socialChannels
  const creatorChannels = creator?.user?.socialProfiles
  const platforms = ['instagram', 'youtube', 'tiktok']

  const [selectedChannel, setSelectedChannel] = useState(
    optInChannels?.[0] || campaignChannels?.[0] || creatorChannels?.[0]?.platform
  )
  const [socialProfile, setSocialProfile] = useState(undefined)
  const [engagementDrawerOpen, setEngagementDrawerOpen] = useState(false)
  const [connectionStatus, setConnectionStatus] = useState(undefined)

  useEffect(() => {
    if (optInChannels?.[0] || campaignChannels?.[0]) {
      setSelectedChannel(optInChannels?.[0] || campaignChannels?.[0])
    }
  }, [campaignChannels, optInChannels])

  useEffect(() => {
    setSocialProfile(user?.socialProfiles?.find(profile => profile.platform === selectedChannel))
  }, [selectedChannel, user.socialProfiles])

  const phylloAccountId = socialProfile?.phylloData?.account?.id
  const { data } = useQuery(['phyllo-data', phylloAccountId], async () => {
    if (!phylloAccountId) return { status: 'NOT_CONNECTED' }
    const { data: phylloData } = await axios.get(`${NODE_URL}/phyllo-account/${phylloAccountId}`)
    return phylloData
  })

  useEffect(() => {
    if (data) {
      setConnectionStatus(data.status)
    }
  }, [data])

  return (
    <Wrapper>
      <div className='selectors'>
        {platforms.map(platform => {
          // Check if the user has a social profile for this platform
          const socialProfile = user?.socialProfiles?.find(profile => profile.platform === platform)

          return (
            <div
              key={platform}
              className={`channel-toggle ${selectedChannel === platform && 'selected'} ${
                socialProfile && 'connected'
              }`}
              // Only allow selection if the user has a social profile for this platform
              onClick={() => socialProfile && setSelectedChannel(platform)}
              onKeyDown={() => socialProfile && setSelectedChannel(platform)}
              role='button'
              tabIndex={0}>
              <img className='social-icon' src={socialIcons[platform]} alt='' />
              <span className='label'>
                {platform === 'instagram'
                  ? 'Instagram'
                  : platform === 'youtube'
                    ? 'YouTube'
                    : 'TikTok'}
              </span>
              {campaignChannels?.length > 1 && (
                <div className='opted-in-tag'>
                  <Tooltip title={optInChannels?.includes(platform) ? 'Opted In' : 'Not Opted In'}>
                    {optInChannels?.includes(platform) ? (
                      <CheckSquareOutlined style={{ color: '#027df0' }} />
                    ) : (
                      <CloseSquareOutlined style={{ color: '#999' }} />
                    )}
                  </Tooltip>
                </div>
              )}
            </div>
          )
        })}
      </div>

      <div className='social-profile-info'>
        {selectedChannel && socialProfile && (
          <>
            <div className='channel'>
              <div className='channel-header'>
                <CreatorAvatar
                  className='avatar'
                  size={40}
                  id={user.id}
                  url={socialProfile.profilePicUrl}
                  initials={`${user?.firstName?.charAt(0)?.toUpperCase()} ${user?.lastName
                    ?.charAt(0)
                    .toUpperCase()}`}
                />
                <div className='channel-info'>
                  <a
                    target='_blank'
                    rel='noopener noreferrer'
                    href={socialProfileToUrl(socialProfile)}>
                    <span className='username'>{socialProfile.username}</span>
                  </a>
                  <div
                    className={`connection-status ${
                      connectionStatus === 'NOT_CONNECTED' || connectionStatus === 'SESSION_EXPIRED'
                        ? 'not-connected'
                        : ''
                    }`}>
                    {!connectionStatus ? (
                      <LoadingOutlined spin />
                    ) : (
                      <>
                        {connectionStatus === 'CONNECTED' ? (
                          <CheckCircleOutlined />
                        ) : (
                          <ExclamationCircleOutlined />
                        )}
                        {connectionStatus === 'CONNECTED' && 'Connected'}
                        {connectionStatus === 'NOT_CONNECTED' && 'Not Connected'}
                        {connectionStatus === 'SESSION_EXPIRED' && 'Connection Expired'}
                      </>
                    )}
                  </div>
                </div>
              </div>
              <div className='metrics'>
                <div className='stat'>
                  <div className='label'>
                    {selectedChannel === 'youtube' ? 'Subscribers' : 'Followers'}
                  </div>
                  <div className='num'>
                    {numeral(socialProfile.followerCount).format('0.0a').toUpperCase()}
                  </div>
                </div>
                <div className='stat'>
                  <div className='label'>Engagement</div>
                  <div className='num'>
                    {numeral(socialProfile.engagement).format('0.00a')}%
                    {socialProfile?.metaData?.engagementBreakdown && (
                      <Button type='link' onClick={() => setEngagementDrawerOpen(true)}>
                        <QuestionCircleOutlined />
                      </Button>
                    )}
                  </div>
                </div>
                {selectedChannel === 'youtube' && (
                  <div className='stat'>
                    <div className='label'>Avg. views</div>
                    <div className='num'>
                      {numeral(socialProfile.avgViews).format('0.0a').toUpperCase()}
                    </div>
                  </div>
                )}
                <p className='last-updated'>
                  Last Updated:{' '}
                  {socialProfile.updated ? moment(socialProfile.updated).format('ll') : 'N/A'}
                </p>
              </div>
            </div>
            <div className='section-header'>
              <h2>Deep Insights</h2>
              <Tooltip
                title='Get a deeper look into the audience demographics and metrics of this creator.'
                placement='left'>
                <InfoCircleOutlined />
              </Tooltip>
            </div>
            <div className='deep-insights'>
              <AudienceData
                socialProfileId={user?.socialProfiles?.find(s => s.platform == selectedChannel)?.id}
              />
            </div>
          </>
        )}
      </div>

      <Drawer
        title='Engagement Breakdown'
        open={engagementDrawerOpen}
        onClose={() => setEngagementDrawerOpen(false)}
        destroyOnClose={true}>
        <EngagementBreakdown
          socialProfile={socialProfile}
          engagementBreakdown={socialProfile?.metaData?.engagementBreakdown}
        />
      </Drawer>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .selectors {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    text-align: center;
    user-select: none;
    .channel-toggle {
      background: #eee;
      border-radius: 10px 10px 0 0;
      padding: 10px;
      opacity: 0.5;
      display: flex;
      align-items: center;
      gap: 10px;
      .opted-in-tag {
        margin-left: auto;
      }
      &.connected {
        opacity: 1;
        transition: 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
          background: #ddd;
        }
      }
      &.selected {
        opacity: 1;
        background: #f7f7f7;
        &:hover {
          background: #f7f7f7;
        }
      }
      &:not(.connected) {
        opacity: 0.2;
      }
    }
    .label {
      font-size: 0.7rem;
      text-transform: uppercase;
      letter-spacing: 1px;
    }
    .social-icon {
      height: 20px;
    }
  }
  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h2 {
      font-family: 'Gilroy-ExtraBold', sans-serif;
      color: #23262f;
      font-size: 20px;
      margin: 0;
    }
    .sub-link {
      font-size: 14px;
    }
  }
  .social-profile-info {
    background: #f7f7f7;
    padding: 20px;
    border-radius: 0 0 10px 10px;
  }
  .channel {
    background: #ecf6ff;
    border: 1px solid #b6dcff;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    padding: 10px;
    margin-bottom: 20px;
    .admin-bar {
      background: #e9f4fc;
      border: 1px dotted ${props => props.theme.crcoTechBlue};
      border-radius: 5px;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
    }
    .channel-header {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;

      .channel-info {
        font-size: 1rem;
        display: flex;
        flex-direction: column;

        .connection-status {
          font-size: 0.8rem;
          opacity: 0.5;

          &.not-connected {
            color: red;
          }

          .anticon {
            margin-right: 5px;
          }
        }
      }
    }
    .bio {
      margin: 0;
    }
    .metrics {
      display: flex;
      flex-direction: column;
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      .stat {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-top: 5px;
        border-bottom: 1px dotted rgba(0, 0, 0, 0.1);
        &:last-child {
          border: 0;
        }
      }
      .label {
        text-transform: uppercase;
        letter-spacing: 1px;
        font-size: 0.8rem;
        color: rgba(0, 0, 0, 0.6);
      }
      .num {
        font-size: 1rem;
        color: ${props => props.theme.crcoMidnight};
        display: flex;
        align-items: center;
        gap: 6px;
        button {
          padding: 0;
          margin: 0;
          border: 0;
          height: fit-content;
        }
      }
      .last-updated {
        font-size: 0.8rem;
        opacity: 0.5;
        margin: 10px 0 0 0;
      }
    }
  }
  .deep-insights {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
  }
`

export default Channels
