// Helper function to check if url comes from a video
export const isVideo = url => {
  try {
    const ext = new URL(url)?.pathname?.toLowerCase()
    return ext?.endsWith('mp4') || ext?.endsWith('mov')
  } catch {
    return false
  }
}
// String help
export const lowercaseFirstChar = inputString => {
  if (typeof inputString !== 'string' || inputString.length === 0) {
    return inputString
  }
  return inputString.charAt(0).toLowerCase() + inputString.slice(1)
}
export const uppercaseFirstChar = inputString => {
  if (typeof inputString !== 'string' || inputString.length === 0) {
    return inputString
  }
  return inputString.charAt(0).toUpperCase() + inputString.slice(1)
}
export const toLowerCamelCase = inputString => {
  return lowercaseFirstChar(inputString.replaceAll(' ', ''))
}

export const socialProfileToUrl = profile => {
  if (!profile) return ''
  if (profile.platform === 'tiktok') {
    return `https://tiktok.com/@${profile.username}`
  }
  return profile.profileUrl
}

export const findProfile = (profiles, platform) =>
  profiles.find(profile => profile.platform === platform)

export const getProfilePicUrl = (optIn, campaign) => {
  // get profile pic from first channel selected by user for this campaign, first channel required for campaign, or default to instagram
  const { socialProfiles } = optIn.user

  const defaultPlatform =
    optIn.extraData?.channels?.[0] ||
    // TODO: remove optIn?.campaign? and use campaign directly.
    optIn?.campaign?.socialChannels?.[0] ||
    campaign?.socialChannels?.[0] ||
    'instagram'

  return socialProfiles?.find(profile => profile.platform === defaultPlatform)?.profilePicUrl
}

export const getAverageRating = rating => {
  const averageRating =
    ((rating.content || 0) +
      (rating.creativity || 0) +
      (rating.communication || 0) +
      (rating.overall || 0)) /
    4

  return Number(averageRating.toFixed(2))
}

// Extract from a URL the file name
export const extractFileNameFromUrl = url => {
  try {
    return new URL(url)?.pathname.split('/').pop()
  } catch (e) {
    return url
  }
}
