import {
  HeartFilled,
  EditOutlined,
  PlusOutlined,
  WarningOutlined,
  LoadingOutlined,
} from '@ant-design/icons'
import { Rate, Button, Modal } from 'antd'
import React, { useState, useContext } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components/macro'

import { InfluencerContext } from '../../../contexts/InfluencerContext'
import { theme } from '../../../GlobalStyles'
import { OptInRating } from '../tabs/opt-ins/OptInRating'

export const Rating = ({ userId, optIn, brandId }) => {
  const { fetchAverageRating } = useContext(InfluencerContext)
  const [rateModalOpen, setRateModalOpen] = useState(false)

  const { data, status } = useQuery(['average-rating', userId], fetchAverageRating)

  const optInRating = optIn?.rating
    ? (optIn.rating.content +
        optIn.rating.creativity +
        optIn.rating.communication +
        optIn.rating.overall) /
      4
    : null

  return (
    <Wrapper>
      {status === 'loading' && (
        <span className='rating-info-text'>
          <LoadingOutlined spin /> Checking ratings...
        </span>
      )}

      {status === 'error' && (
        <span className='rating-info-text'>
          <WarningOutlined /> Failed to load ratings
        </span>
      )}

      {status === 'success' && (
        <>
          <Rate
            disabled={true}
            allowHalf={true}
            value={data.averageRating}
            character={<HeartFilled />}
            style={{ color: theme.crcoCoral }}
          />

          <span className='rating-info-text'>
            <p>
              {!data.totalRatings
                ? 'No ratings yet'
                : `Avg Rating: ${data.averageRating.toFixed(1)} (${data.totalRatings} rating${data.totalRatings !== 1 ? 's' : ''})`}
            </p>
            {optInRating && (
              <p className='rating-info-text'>Your Rating: {optInRating.toFixed(1)}</p>
            )}
          </span>

          {optIn && brandId && (
            <>
              <Button
                type='link'
                onClick={() => setRateModalOpen(true)}
                icon={optIn.rating ? <EditOutlined /> : <PlusOutlined />}>
                {optIn.rating ? 'Edit' : 'Add'} Rating
              </Button>
              <Modal
                title={`${optIn.rating ? 'Edit' : 'Add'} Rating`}
                open={rateModalOpen}
                onCancel={() => setRateModalOpen(false)}
                footer={null}>
                <OptInRating optIn={optIn} brandId={brandId} />
              </Modal>
            </>
          )}
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;

  .rating-info-text {
    color: ${theme.crcoGrey};
    font-size: 0.8rem;
    p {
      margin: 0;
    }
  }
`
