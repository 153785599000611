import { Button, Tooltip } from 'antd'
import axios from 'axios'
import React, { useState } from 'react'
import { queryCache } from 'react-query'

import { NODE_URL } from '../../../constants'

const CycleAffiliateDomain = ({ brandId, optInId }) => {
  const [loading, setLoading] = useState(false)

  const replaceLinks = () => {
    setLoading(true)
    axios
      .post(`${NODE_URL}/brand/${brandId}/opt-in/${optInId}/cycle-affiliate-domain`)
      .then(async () => {
        await queryCache.invalidateQueries('opt-ins')
        return
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <Tooltip
        title={
          "Create new affiliate links if any get flagged. Flagged links may result from creators using content or practices that don't meet social media platform policies"
        }>
        <Button type='secondary' loading={loading} onClick={replaceLinks}>
          Generate new links
        </Button>
      </Tooltip>
    </div>
  )
}

export default CycleAffiliateDomain
