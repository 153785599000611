import { Select } from 'antd'
import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'

import HorizontalBar from '../../../charts/HorizontalBar'
import Pie from '../../../charts/Pie'

const AudienceDataInner = ({ report, availableSubsets }) => {
  const initialAnimation = useRef(true)
  const [subset, setSubset] = useState(availableSubsets[0].name)
  const reach = 100

  const followersAges = report[subset]?.data?.audience_ages
  const ages = followersAges?.slice(0, 3)
  const otherAges =
    followersAges?.length > 3
      ? [
          followersAges
            .slice(3)
            .filter(item => parseFloat(item.weight))
            .reduce((acc, val) => acc + parseFloat(val.weight), 0),
        ]
      : [0]
  const ageData = {
    labels: ages ? ages.reduce((acc, val) => [...acc, val.code], []).concat('35-50+') : [],
    datasets: [
      {
        backgroundColor: '#027DF0',
        categoryPercentage: 0.8,
        barPercentage: 0.8,
        data: ages
          ? ages
              .reduce((acc, val) => [...acc, Math.round(val.weight * 100)], [])
              .concat(Math.round(otherAges[0] * 100))
          : [],
      },
    ],
  }

  const followersGeo = report[subset]?.data?.audience_geo
  const countries = followersGeo?.countries?.slice(0, 3)
  const otherCountries =
    followersGeo?.countries?.length > 3
      ? [
          followersGeo.countries
            .slice(3)
            .filter(item => parseFloat(item.weight))
            .reduce((acc, val) => acc + parseFloat(val.weight), 0),
        ]
      : []
  const geoData = {
    labels: countries
      ? countries
          .reduce((acc, val) => [...acc, val.name.length > 9 ? val.code : val.name], [])
          .concat('Other')
      : [],
    datasets: [
      {
        backgroundColor: '#027DF0',
        categoryPercentage: 0.8,
        barPercentage: 0.8,
        data: countries
          ? countries
              .reduce((acc, val) => [...acc, Math.round(val.weight * 100)], [])
              .concat(Math.round(otherCountries[0] * 100))
          : [],
      },
    ],
  }

  const followersGenders = report[subset]?.data?.audience_genders
  const gendersData = {
    labels: ['Male', 'Female'],
    datasets: [
      {
        backgroundColor: ['#027DF0', '#080830'],
        hoverBackgroundColor: ['#4f7e9e', '#012c45'],
        data: [
          followersGenders
            ? Math.round(followersGenders.filter(item => item.code === 'MALE')[0].weight * 100)
            : 0,
          followersGenders
            ? Math.round(followersGenders.filter(item => item.code === 'FEMALE')[0].weight * 100)
            : 0,
        ],
      },
    ],
  }

  const followersReachability = report[subset]?.data?.audience_reachability
  const reachabilityData = {
    labels: ['<500', '500-1k', '1-1.5k', '>1.5k'],
    datasets: [
      {
        backgroundColor: '#027DF0',
        categoryPercentage: 0.8,
        barPercentage: 0.8,
        data: [
          followersReachability
            ? Math.round(followersReachability.filter(item => item.code === '-500')[0].weight * 100)
            : 0,
          followersReachability
            ? Math.round(
                followersReachability.filter(item => item.code === '500-1000')[0].weight * 100
              )
            : 0,
          followersReachability
            ? Math.round(
                followersReachability.filter(item => item.code === '1000-1500')[0].weight * 100
              )
            : 0,
          followersReachability
            ? Math.round(
                followersReachability.filter(item => item.code === '1500-')[0].weight * 100
              )
            : 0,
        ],
      },
    ],
  }

  const followersLang = report[subset]?.data?.audience_languages
  const lang = followersLang?.slice(0, 2)
  const otherLang =
    followersLang?.length > 2
      ? [
          followersLang
            .slice(2)
            .filter(item => parseFloat(item.weight))
            .reduce((acc, val) => acc + parseFloat(val.weight), 0),
        ]
      : []
  const langData = {
    labels: lang ? lang.reduce((acc, val) => [...acc, val.name], []).concat('Other') : [],
    datasets: [
      {
        backgroundColor: '#027DF0',
        categoryPercentage: 0.8,
        barPercentage: 0.8,
        data: lang
          ? lang
              .reduce((acc, val) => [...acc, Math.round(val.weight * 100)], [])
              .concat(Math.round(otherLang[0] * 100))
          : [],
      },
    ],
  }

  useEffect(() => {
    setSubset(availableSubsets[0].name)
  }, [report.report_info.report_id, availableSubsets[0].name])

  return (
    <Wrapper>
      <div className='header'>
        <span className='header-text'>Audience Demographics</span>
        <div className='filter-wrapper'>
          <span className='label'>Subset:</span>
          <Select
            defaultValue={subset}
            onChange={e => {
              setSubset(e)
            }}>
            {availableSubsets.map(subset => (
              <Select.Option key={subset.name} value={subset.name}>
                {subset.label}
              </Select.Option>
            ))}
          </Select>
        </div>
      </div>
      <div className='inner'>
        <div className='vertical-bars'>
          <div className='age-bar'>
            <HorizontalBar
              key={report.report_info.report_id}
              data={ageData}
              title='Ages:'
              initial={initialAnimation}
              total={reach}
            />
          </div>
          <div className='geo-bar'>
            <HorizontalBar
              key={report.report_info.report_id}
              data={geoData}
              title='Countries:'
              initial={initialAnimation}
              total={reach}
            />
          </div>
        </div>
        <div className='pie'>
          <Pie
            key={report.report_info.report_id}
            data={gendersData}
            title='Genders:'
            initial={initialAnimation}
            total={reach}
          />
        </div>
        <div className='horizontal-bars'>
          <div className='reach-bar'>
            <HorizontalBar
              key={report.report_info.report_id}
              data={reachabilityData}
              title='Reachability:'
              initial={initialAnimation}
              total={reach}
            />
          </div>
          <div className='lang-bar'>
            <HorizontalBar
              key={report.report_info.report_id}
              data={langData}
              title='Languages:'
              initial={initialAnimation}
              langlabel={true}
              total={reach}
            />
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-bottom: 30px;
  .header {
    display: flex;
    justify-content: space-between;
    gap: 8px;
    align-items: center;
    border-bottom: 1px solid #e6e6e6;
    padding-bottom: 10px;
    margin-bottom: 10px;
    .header-text {
      font-size: 16px;
    }
    .filter-wrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }
    .label {
      font-size: 14px;
      margin: 0;
      color: #999;
    }
    .ant-select {
      min-width: 150px;
    }
  }

  .inner {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
  }

  .vertical-bars {
    width: 210px;

    .age-bar,
    .geo-bar {
      height: 120px;
    }
  }
  .pie {
    width: 200px;
    height: 200px;
  }
  .horizontal-bars {
    display: flex;
    .reach-bar,
    .lang-bar {
      width: 230px;
      height: 120px;
    }
  }

  .footer {
    margin-top: 20px;
  }
`

export default AudienceDataInner
