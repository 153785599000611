import { DollarOutlined, PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Empty, Modal, Select, Spin, message } from 'antd'
import axios from 'axios'
import { Formik } from 'formik'
import { useFlags } from 'launchdarkly-react-client-sdk'
import React, { useContext, useEffect, useState } from 'react'
import { queryCache, useQuery } from 'react-query'
import styled from 'styled-components/macro'

import CreateEmailTemplate from '../../../components/forms/CreateEmailTemplate'
import FormItem from '../../../components/forms/FormItem'
import { EmailSettingsNotice } from '../../../components/general/EmailSettingsNotice'
import { NODE_URL } from '../../../constants'
import { BrandContext } from '../../../contexts/BrandContext'
import instagram from '../../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../../images/icons/social/youtube-color-square.svg'
const socialIcons = { instagram, youtube, tiktok }
const { Option } = Select

const CampaignInvite = ({
  socialProfileId,
  socialId, // required in case social profile ID is null for new results in creator search
  username,
  creatorListId,
  creatorListTitle,
  selectedCampaignId,
  close,
}) => {
  const flags = useFlags()
  const { brandId, fetchBrandData, fetchActiveCampaigns } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrandData)

  const accessDenied = !brandData?.emailConfigured && !flags.bypassEmailRequirements

  const { data: campaigns, status: campaignsStatus } = useQuery(
    ['active-campaigns', brandId],
    fetchActiveCampaigns
  )

  const [campaignId, setCampaignId] = useState(selectedCampaignId)
  const [useCustomTemplate, setUseCustomTemplate] = useState(false)
  const [templateId, setTemplateId] = useState(null)
  const [inviteLoading, setInviteLoading] = useState(false)
  const [createTemplateModalOpen, setCreateTemplateModalOpen] = useState(false)

  useEffect(() => {
    if (!campaigns?.length || campaignId) return

    // Set the default campaign to the first one
    setCampaignId(campaigns[0].id)
  }, [campaigns, campaignId])

  useEffect(() => {
    if (campaignId) setEmailTemplateFromCampaign(campaignId)
  }, [campaignId])

  const setEmailTemplateFromCampaign = campaignId => {
    const campaign = campaigns?.find(campaign => campaign.id === campaignId)
    const template = brandData?.emailTemplates.find(
      template => template.id === campaign?.metaData?.emailTemplate
    )
    if (!template) {
      setUseCustomTemplate(false)
      setTemplateId(null)
      return
    }

    setUseCustomTemplate(true)
    setTemplateId(template.id)
  }

  const sendInviteEmail = () => {
    setInviteLoading(true)
    axios
      .post(`${NODE_URL}/campaign-invite/${brandId}`, {
        socialProfileId,
        socialId,
        creatorListId,
        campaignId,
        templateId: useCustomTemplate && templateId,
      })
      .then(() => {
        message.success(`Invite sent to ${username || creatorListTitle}.`)
        queryCache.invalidateQueries('search-invites')
      })
      .catch(err => {
        message.error(err?.response?.data?.msg || 'An unknown error occurred.')
      })
      .finally(() => close())
  }

  return (
    <Wrapper>
      {campaignsStatus === 'success' ? (
        campaigns?.length ? (
          <div className='confirm-invite'>
            {accessDenied ? (
              <EmailSettingsNotice />
            ) : (
              <Formik>
                <>
                  <p>
                    Are you sure you want to invite{' '}
                    {creatorListId ? (
                      <span>
                        all the contacts in the <span className='username'>{creatorListTitle}</span>{' '}
                        list
                      </span>
                    ) : (
                      <span className='username'>{username || 'this user'}</span>
                    )}
                    ?
                  </p>
                  <p>
                    We will send an automated email detailing the steps they need to take to
                    successfully opt in to your campaign.
                  </p>
                  <div className='invite-form'>
                    <FormItem label='Campaign' size='small'>
                      <Select
                        className='campaign-select'
                        name='invite'
                        getPopupContainer={trigger => trigger.parentNode}
                        placeholder='Select campaign'
                        value={campaignId}
                        onChange={e => setCampaignId(e)}>
                        {campaigns?.map(campaign => (
                          <Option key={campaign.id} value={campaign.id}>
                            {campaign.socialChannels?.map((channel, i) => (
                              <img
                                className='social-icon'
                                src={socialIcons[channel]}
                                alt={channel}
                                key={i}
                              />
                            ))}
                            {campaign.maxPaidAmount && (
                              <span className='paid-indicator'>
                                <DollarOutlined />
                              </span>
                            )}
                            {campaign.title}
                          </Option>
                        ))}
                      </Select>
                    </FormItem>

                    <div className='template-select'>
                      <FormItem label='Email Template' size='small'>
                        <Select
                          value={useCustomTemplate}
                          onChange={value => {
                            setUseCustomTemplate(value)
                            if (!value) setTemplateId(null)
                          }}
                          style={{ width: '100%' }}>
                          <Select.Option value={false}>Use Default Message</Select.Option>
                          <Select.Option value={true}>Use Custom Message</Select.Option>
                        </Select>
                      </FormItem>
                      {useCustomTemplate && (
                        <div className='custom-templates'>
                          <Select
                            onChange={e => setTemplateId(e)}
                            className='custom-template-select'
                            name='invite'
                            getPopupContainer={trigger => trigger.parentNode}
                            placeholder='Select template'
                            value={templateId}
                            notFoundContent={`No email templates found for ${brandData?.name}`}
                            options={brandData?.emailTemplates?.map(template => ({
                              key: template.id,
                              label: template.label,
                              value: template.id,
                            }))}
                            dropdownRender={menu => {
                              return (
                                <>
                                  {menu}
                                  <Divider style={{ margin: '8px 0' }} />
                                  <Button
                                    htmlType='button'
                                    type='link'
                                    style={{
                                      paddingLeft: '10px',
                                      paddingRight: '10px',
                                    }}
                                    onClick={() => setCreateTemplateModalOpen(true)}>
                                    <PlusOutlined /> New Template
                                  </Button>
                                </>
                              )
                            }}
                          />
                          <Modal
                            zIndex={1002}
                            title='Create Email Template'
                            onCancel={() => setCreateTemplateModalOpen(false)}
                            footer={null}
                            destroyOnClose
                            open={createTemplateModalOpen}>
                            <CreateEmailTemplate
                              close={() => setCreateTemplateModalOpen(false)}
                              campaignId={campaignId}
                            />
                          </Modal>
                        </div>
                      )}
                    </div>

                    <div className='buttons'>
                      <Button type='secondary' onClick={close}>
                        Cancel
                      </Button>
                      <Button
                        type='primary'
                        loading={inviteLoading}
                        disabled={!campaignId}
                        onClick={sendInviteEmail}>
                        Send Invite
                      </Button>
                    </div>
                  </div>
                </>
              </Formik>
            )}
          </div>
        ) : (
          <Empty description='No active campaigns.' />
        )
      ) : (
        <div className='loading'>
          <Spin />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .username {
    color: ${props => props.theme.crcoTechBlue};
  }

  .invite-form {
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-top: 20px;

    .ant-btn {
      width: fit-content;
    }
  }

  .no-contact {
    text-align: center;
  }

  .campaign-select {
    width: 100%;
    .social-icon {
      height: 17px;
      margin-right: 5px;
    }
    .paid-indicator {
      margin-right: 5px;
      color: ${props => props.theme.crcoTechBlue};
    }
  }

  .template-select {
    .ant-radio-wrapper {
      display: block;
    }

    .custom-templates {
      margin-top: 10px;
    }

    .custom-template-select {
      width: 100%;
      display: block;
    }
  }

  .loading {
    display: grid;
    place-content: center;
    gap: 20px;
    margin: 20px 0;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 20px;
  }
`

export default CampaignInvite
