import { Empty, Tabs } from 'antd'
import React from 'react'
import { useHistory, useParams } from 'react-router'
import styled from 'styled-components/macro'

import CampaignMetrics from './CampaignMetrics'
import Content from './content/Content'
import { OptInsTab } from './opt-ins/OptInsTab'
import Overview from './overview/Overview'
import CampaignPayments from './payments/CampaignPayments'

const CampaignTabs = ({ campaign }) => {
  const { tab } = useParams()
  const history = useHistory()

  return (
    <Wrapper>
      {campaign.optIns ? (
        <Tabs
          defaultActiveKey={campaign.strategy === 'affiliate' ? 'opt-ins' : 'overview'}
          activeKey={tab}
          onChange={key => history.push(`/campaigns/${campaign.id}/${key}`)}
          items={[
            campaign.strategy !== 'affiliate' && {
              key: 'overview',
              label: 'Overview',
              children: <Overview campaign={campaign} />,
            },
            campaign.strategy !== 'affiliate' && {
              key: 'metrics',
              label: 'Metrics',
              children: <CampaignMetrics campaign={campaign} />,
            },
            {
              key: 'opt-ins',
              label: 'Opt-Ins',
              children: <OptInsTab campaign={campaign} />,
            },
            campaign.strategy !== 'affiliate' && {
              key: 'shoutouts',
              label: 'Content',
              children: <Content />,
            },
            campaign.maxPaidAmount &&
              campaign.metaData?.paymentBeta && {
                key: 'payments',
                label: 'Payments',
                children: <CampaignPayments />,
              },
          ]}
        />
      ) : (
        <div className='no-optins'>
          <Empty description='No opt-ins yet.' />
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;

  .ant-tabs-nav {
    margin: 0;
    border-bottom: 1px solid #dbdbee;
  }
  .ant-tabs-nav-wrap {
    margin-bottom: -1px; // hide the bottom border
  }
  .ant-tabs {
    height: 100%;
  }
  .ant-tabs-tab {
    background: #dbdbee;
    color: ${props => props.theme.crcoMidnight};
    font-size: 16px;
    padding: 4px 18px;
    border-radius: 5px 5px 0 0;
    margin: 0 20px 0 0;
    cursor: pointer;
    transition: 0.2s ease-in-out !important;
    border: 1px solid transparent;
    border-bottom: none;
    &.ant-tabs-tab-active {
      background: #fff;
      border-color: #dbdbee;
      .ant-tabs-tab-btn {
        color: ${props => props.theme.crcoTechBlue};
      }
    }
  }
  .ant-tabs-content-holder {
    background: #fff;
    border-left: 1px solid #dbdbee;
    border-right: 1px solid #dbdbee;
    .ant-tabs-content {
      height: 100%;
      .ant-tabs-tabpane {
        height: 100%;
      }
    }
  }

  .dot::before {
    content: '';
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: block;
    position: absolute;
    background: ${props => props.theme.crcoLettuce};
    border: 4px solid rgba(24, 217, 110, 0.33);
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    top: 16px;
    margin-right: 80%;
    right: 12px;
    box-sizing: content-box;
    transform: scale(1);
    animation: pulse 1s infinite;
  }

  @keyframes pulse {
    0% {
      transform: scale(1.1);
    }
    50% {
      transform: scale(1.3);
    }

    75% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.95);
    }
  }

  .no-optins {
    height: 100%;
    display: grid;
    place-items: center;
  }
`

export default CampaignTabs
