import {
  CaretLeftFilled,
  CaretRightFilled,
  RightOutlined,
  LeftOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { Button, Tag, message } from 'antd'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'

import BRAND_ACTIVATE_INFLUENCER from './BRAND_ACTIVATE_INFLUENCER'
import BRAND_REVIEW_CONTENT_PREVIEW from './BRAND_REVIEW_CONTENT_PREVIEW'
import BRAND_SUBMIT_GIVEAWAY_SHIPMENT_TRACKING_LINK from './BRAND_SUBMIT_GIVEAWAY_SHIPMENT_TRACKING_LINK'
import BRAND_SUBMIT_SHIPMENT_TRACKING_LINK from './BRAND_SUBMIT_SHIPMENT_TRACKING_LINK'
import BRAND_SUBMIT_WEB_REDEMPTION_CODE from './BRAND_SUBMIT_WEB_REDEMPTION_CODE'
import CreatorSubmitSocialPostStep from './CreatorSubmitSocialPostStep'
import CustomStep from './CustomStep'
import GiveawayWinnerDetailsStep from './GiveawayWinnerDetailsStep'
import INFLUENCER_SUBMIT_CONTENT_PREVIEW from './INFLUENCER_SUBMIT_CONTENT_PREVIEW'
import { NODE_URL } from '../../constants'
import { getProfilePicUrl } from '../../utils'
import { OptInRating } from '../campaign-dashboard/tabs/opt-ins/OptInRating'
import CreatorAvatar from '../general/CreatorAvatar'

const OrderSteps = ({ optIn, campaign, setSelectedOptIn, setProfileOpen, brand }) => {
  // In the case the user step is not set, it auto-sets to the first step.
  const defaultCampaginStepPlacement = Math.min(1, campaign.steps?.length || 1)
  const [selectedStepPlacement, setSelectedStepPlacement] = useState(
    optIn.currentStep?.placement || defaultCampaginStepPlacement
  )
  const [selectedStep, setSelectedStep] = useState(null)
  const profilePicUrl = getProfilePicUrl(optIn)
  const [updatingCurrentStep, setUpdatingCurrentStep] = useState(false)

  useEffect(() => {
    if (optIn.currentStep?.placement) setSelectedStepPlacement(optIn.currentStep.placement)
  }, [optIn])

  useEffect(() => {
    setSelectedStep(
      optIn.optInSteps?.filter(step => step.campaignStep.placement === selectedStepPlacement)[0]
    )
  }, [selectedStepPlacement, optIn])

  const renderProgrammedStep = (campaignStep, optInStep) => {
    switch (campaignStep.step?.slug) {
      case 'creator-submit-social-post-url':
        return (
          <CreatorSubmitSocialPostStep
            optIn={optIn}
            campaignStep={campaignStep}
            optInStep={optInStep}
            setSelectedStepPlacement={setSelectedStepPlacement}
          />
        )
      case 'creator-provide-giveaway-winner-details':
        return (
          <GiveawayWinnerDetailsStep
            optIn={optIn}
            campaignStep={campaignStep}
            optInStep={optInStep}
            setSelectedStepPlacement={setSelectedStepPlacement}
          />
        )
      case 'deprecated-brand-activate-influencer':
        return <BRAND_ACTIVATE_INFLUENCER optInStep={optInStep} />
      case 'deprecated-influencer-submit-content-preview':
        return (
          <INFLUENCER_SUBMIT_CONTENT_PREVIEW
            optIn={optIn}
            campaignStep={campaignStep}
            optInStep={optInStep}
            setSelectedStepPlacement={setSelectedStepPlacement}
          />
        )
      case 'deprecated-brand-review-content-preview':
        return (
          <BRAND_REVIEW_CONTENT_PREVIEW
            optIn={optIn}
            campaignStep={campaignStep}
            optInStep={optInStep}
          />
        )
      case 'deprecated-brand-score-shoutout':
        return <OptInRating optIn={optIn} brandId={campaign.brand.id} />
      case 'deprecated-brand-submit-giveaway-shipment-tracking-link':
        return (
          <BRAND_SUBMIT_GIVEAWAY_SHIPMENT_TRACKING_LINK
            optIn={optIn}
            campaignStep={campaignStep}
            optInStep={optInStep}
          />
        )
      case 'deprecated-brand-submit-shipment-tracking-link':
        return <BRAND_SUBMIT_SHIPMENT_TRACKING_LINK optIn={optIn} campaignStep={campaignStep} />
      case 'deprecated-brand-submit-web-redemption-code':
        return <BRAND_SUBMIT_WEB_REDEMPTION_CODE optIn={optIn} campaignStep={campaignStep} />
      case 'deprecated-brand-submit-web-redemption-code-2':
        return <div>Submit Unique Redemption Code 2</div>
      case 'deprecated-brand-submit-unique-promotion-code':
        return <BRAND_SUBMIT_WEB_REDEMPTION_CODE optIn={optIn} campaignStep={campaignStep} />
      default:
        return
    }
  }

  const handleSetCurrentStep = async campaignStep => {
    setUpdatingCurrentStep(true)
    try {
      await axios.put(`${NODE_URL}/brand/${brand.id}/opt-in/${optIn.id}`, {
        currentStepId: campaignStep.id,
      })
      await queryCache.invalidateQueries('opt-ins') // wait for data to be updated
    } catch (err) {
      message.error(err.response?.data?.err || 'An unknown error occurred')
    } finally {
      setUpdatingCurrentStep(false)
    }
  }

  return (
    <Wrapper>
      <div className='heading'>
        <div className='actions'>
          <Button type='link' onClick={() => setSelectedOptIn(optIn.prev)} disabled={!optIn.prev}>
            <LeftOutlined />
          </Button>
          <div
            className='avatar'
            onClick={() => setProfileOpen(true)}
            onKeyDown={() => setProfileOpen(true)}
            role='button'
            tabIndex={0}>
            <CreatorAvatar
              size={80}
              id={optIn.user.id}
              url={profilePicUrl}
              initials={`${optIn.user.firstName?.charAt(0).toUpperCase()} ${optIn.user.lastName
                ?.charAt(0)
                .toUpperCase()}`}
            />
          </div>
          <Button type='link' onClick={() => setSelectedOptIn(optIn.next)} disabled={!optIn.next}>
            <RightOutlined />
          </Button>
        </div>
        <div className='name'>
          {optIn.user.firstName} {optIn.user.lastName}
        </div>
      </div>

      {campaign.steps?.map(campaignStep => {
        const optInStep = optIn.optInSteps?.filter(
          optInStep => optInStep.campaignStep.placement === campaignStep.placement
        )[0]

        return (
          <div
            className={`step ${
              selectedStepPlacement === campaignStep.placement && 'selected'
            } ${optIn.currentStep?.placement === campaignStep.placement && 'current'} ${
              selectedStep?.completed && 'completed'
            }`}
            key={campaignStep.id}>
            <div
              className={`nav ${!(selectedStepPlacement > 1) ? 'disabled' : ''}`}
              disabled={!(selectedStepPlacement > 1)}
              onClick={() =>
                selectedStepPlacement > 1 && setSelectedStepPlacement(prev => prev - 1)
              }
              onKeyDown={() =>
                selectedStepPlacement > 1 && setSelectedStepPlacement(prev => prev - 1)
              }
              role='button'
              tabIndex={0}>
              <CaretLeftFilled className='navigation' />
            </div>

            <div className='inner'>
              <div className='step-heading'>
                <p className='title'>
                  <b>Step #{campaignStep.placement}</b>:{' '}
                  {campaignStep.step?.title.replace('Old: ', '') || campaignStep.customStepTitle}
                </p>

                <div className='step-heading-btns'>
                  {/* CURRENT STEP */}
                  {optIn.currentStep?.placement === campaignStep.placement && (
                    <Tag
                      icon={
                        optInStep?.completed ? (
                          <CheckCircleOutlined />
                        ) : campaignStep.actionee === 'creator' ? (
                          <ClockCircleOutlined />
                        ) : (
                          <ExclamationCircleOutlined />
                        )
                      }
                      color={
                        optInStep?.completed
                          ? 'success'
                          : campaignStep.actionee === 'creator'
                            ? 'default'
                            : 'orange'
                      }>
                      {optInStep?.completed
                        ? `Completed on ${moment(optInStep.completed).format('ll')}`
                        : campaignStep.actionee === 'creator'
                          ? 'Waiting on Creator'
                          : 'Action Required'}
                    </Tag>
                  )}

                  {brand?.userHasAgencyPrivileges &&
                    optIn.currentStep?.placement !== campaignStep.placement && (
                      <Button
                        type='secondary'
                        onClick={() => handleSetCurrentStep(campaignStep)}
                        loading={updatingCurrentStep}>
                        Set as Current Step
                      </Button>
                    )}

                  {optIn.currentStep && campaignStep.placement !== optIn.currentStep?.placement && (
                    <Button
                      type='link'
                      onClick={() => setSelectedStepPlacement(optIn.currentStep?.placement)}>
                      View Current Step
                    </Button>
                  )}
                </div>
              </div>

              <div className='actions'>
                {campaignStep.stepId ? (
                  renderProgrammedStep(campaignStep, optInStep)
                ) : (
                  <CustomStep
                    optIn={optIn}
                    campaignStep={campaignStep}
                    optInStep={optInStep}
                    setSelectedStepPlacement={setSelectedStepPlacement}
                  />
                )}
              </div>
            </div>

            <div
              className={`nav ${
                !(selectedStepPlacement < campaign.steps.length) ? 'disabled' : ''
              }`}
              disabled={!(selectedStepPlacement < campaign.steps.length)}
              onClick={() =>
                selectedStepPlacement < campaign.steps.length &&
                setSelectedStepPlacement(prev => prev + 1)
              }
              onKeyDown={() =>
                selectedStepPlacement < campaign.steps.length &&
                setSelectedStepPlacement(prev => prev + 1)
              }
              role='button'
              tabIndex={0}>
              <CaretRightFilled />
            </div>
          </div>
        )
      })}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  & > .heading {
    text-align: center;
    margin-bottom: 10px;
    .actions {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
      margin-bottom: 10px;
      .avatar {
        border-radius: 50%;
        border: 3px solid ${props => props.theme.crcoTechBlue};
        cursor: pointer;
        transition: 0.2s ease-in-out;
        &:hover {
          border-color: ${props => props.theme.crcoMidnight};
        }
      }
    }
    .name {
      font-size: 18px;
    }
  }

  .step {
    background-color: #f7f7f7;
    align-items: stretch;
    display: none; // hide until selected
    justify-content: center;
    min-height: 120px;
    border-radius: 5px;
    overflow: hidden;

    .inner {
      flex: 1;
      padding: 16px 0;
    }

    .nav {
      font-size: 1.2rem;
      min-width: 28px;
      cursor: pointer;
      transition: 0.2s;
      color: #ccc;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 8px;
      box-sizing: content-box;
      &:hover {
        background-color: #eee;
      }

      &.disabled {
        cursor: default;
        opacity: 0.2;

        &:hover {
          background-color: transparent;
        }
      }
    }

    .step-heading {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      margin-bottom: 24px;
      box-shadow: 0px 10px 10px -13px #00000036;
      padding-bottom: 16px;

      .title {
        color: ${props => props.theme.crcoGrey};
        font-size: 18px;
        text-align: center;
        flex: 1;
        margin: 0;
      }

      .step-heading-btns {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
      }
    }

    &.current {
      .title {
        color: ${props => props.theme.crcoTechBlue};
      }
    }

    &.selected {
      display: flex;
    }
  }
`

export default OrderSteps
