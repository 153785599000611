import {
  CheckCircleOutlined,
  MailOutlined,
  InfoCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Button, Drawer, Tooltip, Empty, Spin, Modal, Tag } from 'antd'
import axios from 'axios'
import moment from 'moment'
import numeral from 'numeral'
import React, { useState, useContext } from 'react'
import { useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { EngagementBreakdown } from '../../../components/campaign-dashboard/influencer-profile/EngagementBreakdown'
import AudienceData from '../../../components/general/creator/AudienceData'
import { ImageLoader } from '../../../components/general/ImageLoader'
import { NODE_URL } from '../../../constants'
import { BrandContext } from '../../../contexts/BrandContext'
import instagram from '../../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../../images/icons/social/youtube-color-square.svg'
import { socialProfileToUrl } from '../../../utils'
import CampaignInvite from '../lists/CampaignInvite'
const socialIcons = { instagram, youtube, tiktok }

export const SearchProfile = ({ socialProfile, close }) => {
  const { brandId } = useContext(BrandContext)

  // #region STATES
  const [inviteModalVisible, setInviteModalVisible] = useState(false)
  const [engagementDrawerOpen, setEngagementDrawerOpen] = useState(false)
  // #endregion STATES

  // #region QUERIES
  const { data: invitesData, status: invitesStatus } = useQuery(
    ['search-invites', brandId],
    async () => {
      const { data } = await axios.post(`${NODE_URL}/campaign-invites/${brandId}/search`, {
        username: socialProfile.username,
      })
      return data
    }
  )
  // #endregion QUERIES

  return (
    <Wrapper>
      <section className='creator'>
        <div className='avatar'>
          <ImageLoader src={socialProfile.profilePicUrl} />
        </div>
        <div className='info'>
          <h1 className='fullname'>{socialProfile.fullname}</h1>
          <div className='username'>
            <img className='social-icon' src={socialIcons[socialProfile.platform]} alt='' />
            <a href={socialProfileToUrl(socialProfile)} target='_blank' rel='noopener noreferrer'>
              @{socialProfile.username}
            </a>
            {socialProfile.verified && (
              <Tooltip
                title={
                  <span>
                    This channel or profile is verified, which means that{' '}
                    {socialProfile === 'instagram'
                      ? 'Instagram'
                      : socialProfile === 'tiktok'
                        ? 'TikTok'
                        : socialProfile === 'youtube' && 'YouTube'}{' '}
                    has confirmed that it belongs to the person or brand it represents.
                  </span>
                }>
                <span className='verified'>
                  <CheckCircleOutlined />
                </span>
              </Tooltip>
            )}
          </div>
        </div>
      </section>

      {socialProfile?.userId && (
        <section className='registered'>
          <Tag color='green' style={{ marginTop: 5 }} icon={<CheckCircleOutlined />}>
            Registered on Creator.co
          </Tag>
        </section>
      )}

      <section className='stats-section'>
        <div className='section-header'>
          <h2>Metrics</h2>
          <span className='last-updated'>
            Last Updated:{' '}
            {socialProfile.updated ? moment(socialProfile.updated).format('ll') : 'N/A'}
          </span>
        </div>
        <div className='stats-wrapper'>
          <div className='stat'>
            <span className='label'>
              {socialProfile.platform === 'youtube' ? 'Subscribers' : 'Followers'}
            </span>
            <span className='value'> {numeral(socialProfile.followerCount).format('0a')}</span>
          </div>
          <div className='stat'>
            <span className='label'>Engagement Rate</span>
            <span className='value'>
              {Math.round(socialProfile.engagement * 1000) / 1000}%
              {socialProfile?.extraData?.engagementBreakdown && (
                <Button type='link' onClick={() => setEngagementDrawerOpen(true)}>
                  <QuestionCircleOutlined />
                </Button>
              )}
            </span>
          </div>
          {!!socialProfile.avgViews && (
            <div className='stat'>
              <span className='label'>Average Views</span>
              <span className='value'>{numeral(socialProfile.avgViews).format('0a')}</span>
            </div>
          )}
        </div>
      </section>

      <section className='deep-insights'>
        <div className='section-header'>
          <h2>Deep Insights</h2>
          <Tooltip
            title='Get a deeper look into the audience demographics and metrics of this creator. Fetching a report costs 1 deep insights credit.'
            placement='left'>
            <InfoCircleOutlined />
          </Tooltip>
        </div>
        <div className='deep-insights-wrapper'>
          <AudienceData socialProfileId={socialProfile.id} socialId={socialProfile.socialId} />
        </div>
      </section>

      {!!socialProfile?.creatorListItems?.length && (
        <section className='lists-section'>
          <div className='section-header'>
            <h2>Creator Lists</h2>
            <Link className='sub-link' onClick={close} to='/creator-search/lists'>
              View All
            </Link>
          </div>
          <div className='list-wrapper'>
            <p className='info-text'>
              <InfoCircleOutlined /> This profile has been added to the following list(s):
            </p>
            {socialProfile.creatorListItems.map((list, i) => (
              <div key={i} className='list-item'>
                <p className='title'>{list.creatorList?.title || 'Untitled'}</p>
                <p className='date'>Added {moment(list?.saved).format('ll')}</p>
              </div>
            ))}
          </div>
        </section>
      )}

      <section className='campaign-invites-section'>
        <div className='section-header'>
          <h2>Campaign Invites</h2>
          <div className='actions'>
            <Link className='sub-link' onClick={close} to='/creator-search/invites'>
              View All
            </Link>
            <Button
              type='primary'
              icon={<MailOutlined />}
              onClick={() => setInviteModalVisible(true)}>
              Invite
            </Button>
          </div>
        </div>
        <div className='list-wrapper'>
          {invitesStatus === 'loading' && (
            <div className='loading'>
              <Spin />
            </div>
          )}
          {invitesStatus === 'success' &&
            (invitesData?.length ? (
              <>
                <p className='info-text'>
                  <InfoCircleOutlined /> This creator has been invited to the following campaign(s):
                </p>
                {invitesData.map((invite, i) => (
                  <div key={i} className='list-item'>
                    <div className='title'>{invite.metaData.campaignTitle}</div>
                    <div className='date'>Invited {moment(invite.savedDate).format('lll')}</div>
                  </div>
                ))}
              </>
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description='No invites found.' />
            ))}
        </div>
      </section>
      <Modal
        title='Invite to campaign'
        open={inviteModalVisible}
        onCancel={() => {
          setInviteModalVisible(false)
        }}
        destroyOnClose
        footer={null}
        zIndex={1001}>
        <CampaignInvite
          socialProfileId={socialProfile.id}
          socialId={socialProfile.socialId}
          username={socialProfile.username}
          close={() => setInviteModalVisible(false)}
        />
      </Modal>
      {socialProfile?.extraData?.engagementBreakdown && (
        <Drawer
          title='Engagement Breakdown'
          open={engagementDrawerOpen}
          onClose={() => setEngagementDrawerOpen(false)}
          destroyOnClose={true}>
          <EngagementBreakdown
            socialProfile={socialProfile}
            engagementBreakdown={socialProfile?.extraData?.engagementBreakdown}
          />
        </Drawer>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background: #e9f4fc;
  color: #23262f;
  min-height: 100%;
  padding: 20px;

  h1,
  h2 {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    color: #23262f;
  }

  h2 {
    font-size: 20px;
  }

  section {
    padding: 20px;
    &.registered {
      padding: 0 20px;
    }
  }

  .section-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    h2 {
      margin: 0;
    }
    .sub-link {
      font-size: 14px;
    }
  }
  .social-icon {
    height: 20px;
    width: 20px;
  }

  .creator {
    display: flex;
    align-items: center;
    gap: 16px;
  }
  .avatar {
    background-color: #eee;
    height: 90px;
    aspect-ratio: 1;
    border: 3px solid #fff;
    border-radius: 50%;
    flex: 0 0 90px;
    overflow: hidden;
  }
  .fullname {
    font-size: 24px;
    margin-bottom: 5px;
  }
  .username {
    color: ${props => props.theme.crcoTechBlue};
    font-size: 18px;
    display: flex;
    align-items: center;
    gap: 6px;
  }
  .verified {
    background: ${props => props.theme.crcoTechBlue};
    color: #fff;
    border-radius: 50%;
    padding: 1px;
    display: grid;
    place-content: center;
    font-size: 0.8rem;
  }
  .last-updated {
    color: #999;
    font-size: 0.8rem;
  }
  .stats-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid #b6dcff;
    border-radius: 10px;
    overflow: hidden;
  }
  .stat {
    background: rgba(255, 255, 255, 0.4);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    &:not(:last-child) {
      border-bottom: 1px solid #b6dcff;
    }
    .label {
      color: #777e90;
      font-size: 16px;
    }
    .value {
      color: ${props => props.theme.crcoTechBlue};
      font-size: 20px;
      font-weight: bold;
      display: flex;
      align-items: center;
      gap: 6px;
      button {
        padding: 0;
        margin: 0;
        border: 0;
        height: fit-content;
      }
    }
  }

  .info-text {
    color: #777e90;
    font-size: 0.8rem;
    margin: 10px 0;
  }

  .deep-insights-wrapper {
    background: #fff;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .list-wrapper {
    background: #fff;
    padding: 10px;
    border-radius: 10px;
    .loading {
      min-height: 100px;
      display: grid;
      place-content: center;
      padding: 20px;
    }
    .list-item {
      padding: 10px 0;
      border-top: 1px solid #eee;
    }
    .title {
      font-size: 14px;
      margin-bottom: 4px;
    }
    .date {
      color: #777e90;
      font-size: 0.8rem;
      margin: 0;
    }
  }

  .actions {
    display: flex;
    align-items: center;
    gap: 12px;
  }
`
