import {
  ExclamationCircleFilled,
  MessageOutlined,
  CheckCircleOutlined,
  CheckCircleFilled,
  PaperClipOutlined,
} from '@ant-design/icons'
import { Checkbox, Tooltip } from 'antd'
import moment from 'moment'
import React from 'react'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components/macro'

import CreatorAvatar from '../../components/general/CreatorAvatar'
import { getProfilePicUrl } from '../../utils'

export const OptInListItem = ({ optIn, campaign, selected, handleSelect }) => {
  const profilePicUrl = getProfilePicUrl(optIn, campaign)
  const initials = `${optIn.user.firstName?.charAt(0).toUpperCase()} ${optIn.user.lastName?.charAt(0).toUpperCase()}`

  return (
    <Wrapper
      to={`/inbox/${campaign.id}/chat/${optIn.id}`}
      key={optIn.id}
      activeClassName='selected'
      exact>
      <Tooltip title={selected ? 'Unselect' : 'Select'}>
        <Checkbox
          onClick={e => e.stopPropagation()} // prevent the navlink from firing
          onChange={e => {
            handleSelect(e, optIn.id)
          }}
          checked={selected}
        />
      </Tooltip>
      <div className='chat-card'>
        <div className='chat-info'>
          <div className='chat-info-text'>
            <div className='name'>
              <span>
                {optIn.user.firstName} {optIn.user.lastName}
              </span>
            </div>
            {optIn.latestMessage ? (
              <div className='latest-message'>
                {!optIn.latestMessage.read ? (
                  optIn.latestMessage.sender === 'creator' ? (
                    <span className='icon unread-creator-message'>
                      <ExclamationCircleFilled />
                    </span>
                  ) : (
                    <span className='icon unread-brand-message'>
                      <CheckCircleOutlined />
                    </span>
                  )
                ) : optIn.latestMessage.sender === 'creator' ? (
                  <span className='icon read-creator-message'>
                    <MessageOutlined />
                  </span>
                ) : (
                  <span className='icon read-brand-message'>
                    <CheckCircleFilled />
                  </span>
                )}
                {optIn.latestMessage.created && (
                  <b>{moment(optIn.latestMessage.created).format('MMM D')} • </b>
                )}
                {optIn.latestMessage.user?.firstName}:{' '}
                {optIn.latestMessage.type === 'image' || optIn.latestMessage.type === 'file' ? (
                  <span>
                    <PaperClipOutlined /> Attachment
                  </span>
                ) : optIn.latestMessage.content.length >= 46 ? (
                  optIn.latestMessage.content.substring(0, 45) + '...'
                ) : (
                  optIn.latestMessage.content
                )}
              </div>
            ) : (
              <div className='latest-message none'>No message history.</div>
            )}
          </div>
          <CreatorAvatar
            className='avatar'
            size={40}
            id={optIn.user.id}
            url={profilePicUrl}
            initials={initials}
          />
        </div>
        <div className='opt-in-info'>
          <p>
            Status:{' '}
            <span className={`status ${optIn.status}`}>
              {optIn.status === 'cancelled'
                ? optIn.cancelledData?.reason === 'expired'
                  ? 'Expired'
                  : 'Declined'
                : optIn.status}
            </span>
          </p>
          {optIn.status !== 'cancelled' && (
            <p className={`step ${optIn?.currentStep?.actionee} `}>
              {optIn?.currentStep?.step?.title?.substring(0, 4) === 'Old:'
                ? optIn?.currentStep?.step?.title?.substring(4)
                : optIn?.currentStep?.step?.title || optIn?.currentStep?.customStepTitle}
            </p>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

const Wrapper = styled(NavLink)`
  background: #f6f6f6;
  color: #080830;
  display: flex;
  gap: 10px;
  padding: 10px;
  border-radius: 5px;
  transition: 0.2s ease-in-out;
  cursor: pointer;
  position: relative;
  font-size: 0.8rem;
  &:hover {
    .chat-info {
      .name {
        color: #027df0;
      }
    }
  }
  &.selected {
    background: ${props => props.theme.crcoPaleBlue};
    .chat-info {
      .name {
        color: #027df0;
      }
    }
  }
  .chat-card {
    width: 100%;
  }
  .chat-info {
    display: flex;
    gap: 5px;
    .chat-info-text {
      width: 100%;
    }
    .name {
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transition: 0.2s ease-in-out;
    }
    .latest-message {
      color: rgba(0, 0, 0, 0.4);
      font-size: 0.7rem;
      word-break: break-word;
      &.none {
        color: rgba(0, 0, 0, 0.2);
      }
      .icon {
        margin-right: 5px;
        &.unread-creator-message {
          color: orange;
        }
        &.unread-brand-message {
        }
        &.read-creator-message {
          display: none;
        }
        &.read-brand-message {
          color: ${props => props.theme.crcoLettuce};
        }
      }
    }
  }
  .opt-in-info {
    color: ${props => props.theme.crcoGrey};
    margin-top: 8px;
    border-top: rgba(0, 0, 0, 0.1) 1px solid;
    padding-top: 5px;
    text-transform: capitalize;
    font-size: 0.7rem;
    p {
      margin: 0;
    }
    .status {
      &.pending {
        color: #666;
      }
      &.activated {
        color: ${props => props.theme.crcoTechBlue};
      }
      &.completed {
        color: ${props => props.theme.crcoLettuce};
      }
    }
    .step {
      &.brand {
        color: ${props => props.theme.crcoCoral};
      }
    }
  }
`
