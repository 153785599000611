import {
  InfoCircleOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  CheckCircleFilled,
  ExclamationCircleFilled,
  HourglassOutlined,
  HomeOutlined,
  FileTextOutlined,
  MailOutlined,
  ShoppingOutlined,
  DollarOutlined,
  StarOutlined,
  StarFilled,
  WarningOutlined,
} from '@ant-design/icons'
import { Button, Checkbox, Modal, Alert, Drawer, message, Tooltip } from 'antd'
import dayjs from 'dayjs'
import { useFlags } from 'launchdarkly-react-client-sdk'
import moment from 'moment'
import numeral from 'numeral'
import React, { useContext, useState } from 'react'
import { queryCache, useQuery } from 'react-query'
import styled from 'styled-components'

import OptInExtras from './OptInExtras'
import { BrandContext } from '../../../../contexts/BrandContext'
import { OptInContext } from '../../../../contexts/OptInContext'
import instagram from '../../../../images/icons/social/instagram-color-square.svg'
import tiktok from '../../../../images/icons/social/tiktok-color-square.svg'
import youtube from '../../../../images/icons/social/youtube-color-square.svg'
import { findProfile, getProfilePicUrl } from '../../../../utils'
import CopyAddress from '../../../general/CopyAddress'
import CreatorAvatar from '../../../general/CreatorAvatar'
import OrderDetails from '../../../general/OrderDetails'
import CreateShopifyOrder from '../../../general/shopify/CreateShopifyOrder'
import NegotiatePayments from '../payments/NegotiatePayments'
import TipsTransactions from '../payments/TipsTransactions'
const socialIcons = { instagram, youtube, tiktok }

export const OptIn = ({
  optIn,
  campaign,
  setShowProfile,
  selectedOptIn,
  setSelectedOptIn,
  selectedOptInRef,
  setStepsOpen,
  selected,
  handleSelect,
  formData,
  setOptIns,
}) => {
  const { activate, decline, reinvite, updateOptIn } = useContext(OptInContext)
  const flags = useFlags()

  const { brandId, fetchBrand } = useContext(BrandContext)
  const { data: brandData } = useQuery(['brand', brandId], fetchBrand)
  const shopifyEnabled = !!brandData?.totalShopifyProducts

  // #region State
  const [errorMsg, setErrorMsg] = useState('')
  const [isActionLoading, setIsActionLoading] = useState(false)
  const [activateVisible, setActivateVisible] = useState(false)
  const [declineVisible, setDeclineVisible] = useState(false)
  const [showAddress, setShowAddress] = useState(false)
  const [paymentOpen, setPaymentOpen] = useState(false)
  const [showExtras, setShowExtras] = useState(false)
  const [reinviteVisible, setReinviteVisible] = useState(false)
  const [reinviteLoading, setReinviteLoading] = useState(false)
  const [showProductModal, setShowProductModal] = useState(false)
  const [showOrderDrawer, setShowOrderDrawer] = useState(false)
  const [favoritedDate, setFavoritedDate] = useState(optIn.favoritedDate)
  // #endregion State

  // #region Constants
  let expiryDays = 30 - dayjs().diff(dayjs(optIn.created)) / 1000 / 60 / 60 / 24
  if (expiryDays < 0) expiryDays = 0
  const { socialProfiles } = optIn.user
  const profilePicUrl = getProfilePicUrl(optIn)
  const platforms =
    // TODO: remove optIn?.campaign?.socialChannels
    optIn.extraData?.channels || optIn?.campaign?.socialChannels || campaign?.socialChannels // user's selected channels or campaign's social channels
  // #endregion Constants

  // #region Functions
  const handleActivate = async () => {
    setIsActionLoading(true)
    let result = await activate(optIn.id)
    setIsActionLoading(false)

    if (result.error) {
      setErrorMsg(result.error)
    } else {
      message.success('Creator activated')
      queryCache.invalidateQueries('opt-ins')
      setActivateVisible(false)
    }
  }

  const handleDecline = async () => {
    setIsActionLoading(true)
    let result = await decline(optIn.id)
    setIsActionLoading(false)

    if (result.error) {
      setErrorMsg(result.error)
    } else {
      // update UI as query takes time to refetch and update
      if (formData.status?.length && !formData.status.includes('cancelled')) {
        // remove from list where cancelled opt-ins are not shown
        setOptIns(prev => prev.filter(o => o.id !== optIn.id))
      } else {
        // update opt-in status
        setOptIns(prev => prev.map(o => (o.id === optIn.id ? { ...o, status: 'cancelled' } : o)))
      }
      message.success('Creator declined')
      queryCache.invalidateQueries('opt-ins')
      setDeclineVisible(false)
    }
  }

  const handleReinvite = async () => {
    if (optIn.reinvited) return // prevent sending multiple reinvites

    setReinviteLoading(true)
    let result = await reinvite(optIn.id)
    setReinviteLoading(false)

    if (!result.error) {
      message.success('Creator reinvited')
      setReinviteVisible(false)
    } else {
      message.error(result.error)
    }
  }

  const handleFavorite = async () => {
    // immediately update UI to show change
    const date = favoritedDate ? null : new Date()
    setFavoritedDate(date)

    let result = await updateOptIn(brandId, optIn.id, { favoritedDate: date })

    if (result.error) {
      // show error message and revert back to previous state in case of error
      message.error(result.error)
      setFavoritedDate(favoritedDate)
    }
  }
  // #endregion Functions

  const invited = optIn.campaignInvites?.find(invite => invite.campaignId === campaign.id)

  return (
    <>
      <Wrapper
        className={`${selectedOptIn?.id === optIn.id && 'selected'} ${selectedOptIn && 'active'}`}
        onClick={() => {
          setSelectedOptIn(optIn.id)
          setShowProfile(true)
        }}
        ref={selectedOptIn?.id === optIn.id ? selectedOptInRef : null}>
        <Tooltip title={selected ? 'Unselect' : 'Select'}>
          <Checkbox
            onClick={e => e.stopPropagation()} // prevent opening the profile
            onChange={e => handleSelect(e, optIn.id)}
            checked={selected}
          />
        </Tooltip>
        <Tooltip title={favoritedDate ? 'Unfavorite' : 'Favorite'}>
          <div
            className={`fave-btn ${favoritedDate && 'favorited'}`}
            onClick={e => {
              e.stopPropagation()
              handleFavorite()
            }}
            onKeyDown={e => {
              e.stopPropagation()
              handleFavorite()
            }}
            role='button'
            tabIndex={0}>
            {favoritedDate ? <StarFilled /> : <StarOutlined />}
          </div>
        </Tooltip>
        {/* COLUMN 1 - AVATAR, NAME, DATES */}
        <div className='basic'>
          <CreatorAvatar
            size={55}
            className='avatar'
            id={optIn.user.id}
            url={profilePicUrl}
            initials={`
              ${optIn.user.firstName ? optIn.user.firstName?.charAt(0).toUpperCase() : ''} 
              ${optIn.user.lastName ? optIn.user.lastName?.charAt(0).toUpperCase() : ''}
            `}
          />
          <div className='text'>
            <span className='name'>
              {optIn.user.firstName} {optIn.user.lastName}
            </span>
            <div className='date'>
              <p className='opt-in-date'>
                {!optIn.seen && <span className='not-seen' />}
                {moment(optIn.created).format('ll')}
                {invited && (
                  <span className='invited-icon'>
                    <MailOutlined />
                  </span>
                )}
              </p>
              {optIn.status === 'pending' && (
                <p className='expiring'>
                  <InfoCircleOutlined /> Expires in {Math.round(expiryDays)} days
                </p>
              )}
              {['activated', 'completed'].includes(optIn.status) && (
                <p className='activation'>
                  <CheckCircleOutlined /> Activated {moment(optIn.activated).format('ll')}
                </p>
              )}
              {optIn.status === 'cancelled' && (
                <p className='expired'>
                  <CloseCircleOutlined />{' '}
                  {optIn.cancelledData?.reason === 'expired' ? 'Expired ' : 'Declined '}
                  {moment(optIn.cancelled).format('ll')}
                </p>
              )}
            </div>
          </div>
        </div>

        {/* COLUMN 2 - METRICS / STEPS */}
        {!['activated', 'completed'].includes(optIn.status) &&
          !!campaign.socialChannels?.length && (
            <div className='metrics'>
              {platforms.map((platform, i) => {
                const socialProfile = findProfile(socialProfiles, platform)
                return (
                  <div className='metric-group' key={i}>
                    <img className='icon' src={socialIcons[platform]} alt={platform} />
                    <div className='text'>
                      <div className='stat'>
                        {platform === 'youtube' ? 'Subscribers' : 'Followers'}:{' '}
                        {numeral(socialProfile?.followerCount || 0).format('0.0a')}
                      </div>
                      <div className='stat'>
                        Engagement: {numeral(socialProfile?.engagement || 0).format('0.0a')}%
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )}

        {['activated', 'completed'].includes(optIn.status) && (
          <div className='steps'>
            <div
              className='open-step'
              onClick={e => {
                e.stopPropagation()
                setSelectedOptIn(optIn.id)
                setStepsOpen(true)
              }}
              onKeyDown={e => {
                e.stopPropagation()
                setSelectedOptIn(optIn.id)
                setStepsOpen(true)
              }}
              role='button'
              tabIndex={0}>
              {optIn.currentStep && (
                <>
                  <div className='title'>
                    <p className='step-num'>Step {optIn.currentStep.placement}:</p>
                    <p>
                      {optIn.currentStep.step?.title.replace('Old: ', '') ||
                        optIn.currentStep.customStepTitle}
                    </p>
                  </div>
                </>
              )}
              <div className='status'>
                {optIn.currentStep ? (
                  optIn.currentStep.actionee === 'brand' ? (
                    <span className='action'>
                      Action required <ExclamationCircleFilled />
                    </span>
                  ) : (
                    <span className='waiting'>
                      Waiting on creator <HourglassOutlined />
                    </span>
                  )
                ) : optIn.status === 'completed' ? (
                  <span className='completed'>
                    Completed <CheckCircleFilled />
                  </span>
                ) : (
                  <span className='error'>
                    <WarningOutlined /> Current step not set
                  </span>
                )}
              </div>
            </div>
          </div>
        )}

        {/* COLUMN 3 - SHOPIFY / VARIATIONS */}
        {['activated', 'completed'].includes(optIn.status) &&
          flags.shopifyOrderCreation &&
          shopifyEnabled &&
          (optIn?.extraData?.ordersData?.length ? (
            <Button
              type='link'
              onClick={e => {
                e.stopPropagation()
                setShowOrderDrawer(true)
              }}>
              Order Details <ShoppingOutlined />
            </Button>
          ) : (
            <Button
              type='link'
              onClick={e => {
                e.stopPropagation()
                setShowProductModal(true)
              }}>
              Create Order <ShoppingOutlined />
            </Button>
          ))}
        {!!campaign.variables?.length && (
          <div className='variations'>
            {optIn.variables?.length ? (
              optIn.variables.map(({ variable, selectedOption }, i) => (
                <div key={i} className='selection'>
                  <b>{variable.title}:</b> {selectedOption.title}
                </div>
              ))
            ) : optIn.extraData?.variables?.length ? (
              optIn.extraData.variables.map((option, i) => (
                <div key={i} className='selection'>
                  {option}
                </div>
              ))
            ) : (
              <div className='no-selection'>No variation selected.</div>
            )}
          </div>
        )}

        {!!campaign.productListId && (
          <div className='product'>
            {optIn.productListItem ? (
              <div className='selection'>
                <Tooltip title={optIn.productListItem.description}>
                  {optIn.productListItem.title}
                </Tooltip>
              </div>
            ) : (
              <div className='no-selection'>No product selected.</div>
            )}
          </div>
        )}

        {/* COLUMN 4 - PAYMENT */}
        <div className='payment'>
          {campaign.metaData?.paymentBeta && campaign.maxPaidAmount ? (
            <NegotiatePayments optIn={optIn} campaign={campaign} />
          ) : (
            <p className='no-payment'>No payment.</p>
          )}
        </div>

        {/* COLUMN 5 - ACTIONS */}
        <div className='actions'>
          {optIn.status === 'pending' && (
            <>
              <Button
                type='link'
                onClick={e => {
                  e.stopPropagation()
                  setActivateVisible(true)
                }}>
                Activate <CheckCircleOutlined />
              </Button>
              <Button
                type='link'
                danger={true}
                onClick={e => {
                  e.stopPropagation()
                  setDeclineVisible(true)
                }}>
                Decline <CloseCircleOutlined />
              </Button>
            </>
          )}
          {['activated', 'completed'].includes(optIn.status) && (
            <>
              {campaign.requiresShipping && (
                <Button
                  type='link'
                  onClick={e => {
                    e.stopPropagation()
                    setShowAddress(true)
                  }}>
                  View Address <HomeOutlined />
                </Button>
              )}
              <Button
                type='link'
                onClick={e => {
                  e.stopPropagation()
                  setPaymentOpen(true)
                }}>
                Send Tip <DollarOutlined />
              </Button>
              {(optIn.extraData.extraContent || optIn.instructions) && (
                <Button
                  type='link'
                  onClick={e => {
                    e.stopPropagation()
                    setShowExtras(true)
                  }}>
                  Extras <FileTextOutlined />
                </Button>
              )}
            </>
          )}
          {optIn.status === 'cancelled' && (
            <Button
              type='link'
              disabled={optIn.reinvited}
              onClick={e => {
                e.stopPropagation()
                setReinviteVisible(true)
              }}>
              {optIn.reinvited ? 'Reinvited' : 'Reinvite'} <MailOutlined />
            </Button>
          )}
        </div>
      </Wrapper>

      {/* MODALS */}
      {optIn.status === 'pending' && (
        <>
          <Modal
            title='Activate Creator'
            open={activateVisible}
            centered={true}
            okText='Activate'
            confirmLoading={isActionLoading}
            onOk={handleActivate}
            onCancel={() => {
              setActivateVisible(false)
              setErrorMsg('')
            }}>
            <ConfirmContainer>
              <CreatorAvatar
                className='avatar'
                size={94}
                url={profilePicUrl}
                id={optIn.id}
                initials={`
                ${optIn.user.firstName?.charAt(0).toUpperCase()} 
                ${optIn.user.lastName?.charAt(0).toUpperCase()}
              `}
              />
              <span className='message'>
                Are you sure you want to activate {`${optIn.user.firstName} ${optIn.user.lastName}`}
                ?
              </span>
              {errorMsg && <Alert type='error' description={errorMsg} />}
            </ConfirmContainer>
          </Modal>
          <Modal
            title='Decline Creator'
            open={declineVisible}
            centered={true}
            okText='Decline'
            okButtonProps={{ danger: true }}
            confirmLoading={isActionLoading}
            onOk={handleDecline}
            onCancel={() => {
              setDeclineVisible(false)
              setErrorMsg('')
            }}>
            <ConfirmContainer>
              <CreatorAvatar
                id={optIn.id}
                className='avatar'
                size={94}
                url={profilePicUrl}
                initials={`
              ${optIn.user.firstName?.charAt(0).toUpperCase()} 
              ${optIn.user.lastName?.charAt(0).toUpperCase()}
            `}
              />
              <span className='message'>
                Are you sure you want to decline {`${optIn.user.firstName} ${optIn.user.lastName}`}?
              </span>
              {errorMsg && <Alert type='error' description={errorMsg} />}
            </ConfirmContainer>
          </Modal>
        </>
      )}

      {['activated', 'completed'].includes(optIn.status) && (
        <>
          <TipsTransactions
            optIn={optIn}
            paymentOpen={paymentOpen}
            setPaymentOpen={setPaymentOpen}
          />
          <Modal
            bodyStyle={{ display: 'grid', placeItems: 'center', padding: '40px' }}
            width={300}
            footer={null}
            open={showAddress}
            onCancel={() => {
              setShowAddress(false)
            }}
            centered={true}>
            <CopyAddress
              address={{
                firstName: optIn.user.firstName,
                lastName: optIn.user.lastName,
                ...optIn.user.creatorProfile,
              }}
            />
          </Modal>
          <Modal
            open={showProductModal}
            destroyOnClose
            onCancel={() => setShowProductModal(false)}
            title='Create Shopify Order'
            footer={null}>
            <CreateShopifyOrder optIn={optIn} close={() => setShowProductModal(false)} />
          </Modal>
          <Drawer
            title={`Order Details for ${optIn.user.firstName} ${optIn.user.lastName}`}
            open={showOrderDrawer}
            width={window.innerWidth > 600 ? 600 : window.innerWidth}
            destroyOnClose
            onClose={() => setShowOrderDrawer(false)}>
            <OrderDetails
              optIn={optIn}
              openProductsModal={() => {
                setShowProductModal(true)
                setShowOrderDrawer(false)
              }}
            />
          </Drawer>
          <Drawer
            open={showExtras}
            onClose={() => setShowExtras(false)}
            width={window.innerWidth > 600 ? 600 : window.innerWidth}>
            <OptInExtras optIn={optIn} />
          </Drawer>
        </>
      )}

      {optIn.status === 'cancelled' && (
        <Modal
          title='Reinvite Creator'
          open={reinviteVisible}
          centered={true}
          okText='Reinvite'
          confirmLoading={reinviteLoading}
          onOk={handleReinvite}
          onCancel={() => {
            setReinviteVisible(false)
          }}>
          <ConfirmContainer>
            <CreatorAvatar
              className='avatar'
              size={94}
              url={profilePicUrl}
              id={optIn.id}
              initials={`
              ${optIn.user.firstName?.charAt(0).toUpperCase()} 
              ${optIn.user.lastName?.charAt(0).toUpperCase()}
            `}
            />
            <span className='message'>
              Are you sure you want to reinvite {`${optIn.user.firstName} ${optIn.user.lastName}`}?
            </span>
          </ConfirmContainer>
        </Modal>
      )}
    </>
  )
}

const Wrapper = styled.div`
  background: #fff;
  width: 100%;
  min-width: fit-content;
  border-radius: 5px;
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 5px 10px;
  position: relative;
  cursor: pointer;
  transition: 0.2s ease-in-out;
  &:hover {
    box-shadow: -3px 3px 10px rgba(0, 0, 0, 0.1);
  }
  &.active {
    &:not(.selected) {
      opacity: 0.3;
    }
  }
  .fave-btn {
    font-size: 1.2rem;
    color: #d9d9d9;
    display: grid;
    place-content: center;
    transition: 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      color: #fadb14;
    }
    &.favorited {
      color: #fadb14;
      &:hover {
        color: #d9d9d9;
      }
    }
  }
  .basic {
    flex: 1;
    min-width: 200px;
    max-width: 300px;
  }
  .metrics,
  .steps,
  .variations,
  .payments {
    flex: 1;
    margin: auto;
    min-width: 200px;
    max-width: 300px;
  }
  .variations,
  .payment,
  .actions {
    min-width: 150px;
  }
  .basic {
    display: flex;
    align-items: center;
    gap: 16px;
    .text {
      .name {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 1rem;
        transition: 0.2s ease-in-out;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .date {
        color: #999;
        font-size: 0.8rem;
        p {
          margin: 0;
          display: -webkit-box;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .opt-in-date {
          display: flex;
          align-items: center;
          gap: 5px;
        }
        .expiring {
          color: #ff9900;
        }
        .expired {
          color: ${props => props.theme.crcoCoral};
        }
        .activation {
          color: ${props => props.theme.crcoTechBlue};
        }
      }
      .invited-icon {
        padding-left: 5px;
      }
    }
  }
  .metrics {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 5px;
    .metric-group {
      background: #f7f7f7;
      border-radius: 5px;
      padding: 5px 10px;
      display: flex;
      align-items: center;
      gap: 8px;
      font-size: 12px;
      color: #777e90;
      width: 100%;
    }
    .icon {
      height: 20px;
      width: 20px;
    }
    .text {
      display: flex;
      align-items: center;
      gap: 8px;
      .stat:first-child {
        padding-right: 8px;
        border-right: 1px solid #ccc;
      }
    }
  }
  .steps {
    text-align: center;
    .open-step {
      user-select: none;
      transition: 0.2s ease-in-out;
      border-radius: 5px;
      padding: 6px;
      display: grid;
      place-items: center;
      min-height: 66px;
      &:hover {
        background-color: #f7f7f7;
      }
      .title {
        .step-num {
          font-size: 0.8rem;
          font-family: 'Campton-Light', Verdana, sans-serif;
        }
        p {
          margin: 0;
        }
      }
      .status {
        margin-top: 6px;
        & > span {
          display: inline-block;
          color: #fff;
          padding: 2px 6px;
          border-radius: 3px;
          font-size: 12px;
          min-width: 150px;
          &.completed {
            background-color: ${props => props.theme.crcoTechBlue};
          }
          &.waiting {
            background-color: #999;
          }
          &.action {
            background-color: ${props => props.theme.crcoCoral};
          }
          &.error {
            background-color: lightgrey;
          }
        }
      }
    }
  }
  .variations {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    font-size: 0.8rem;
    margin-left: auto;
    .selection {
      background: #e9f4fc;
      color: ${props => props.theme.crcoTechBlue};
      padding: 2px 5px;
      border-radius: 5px;
    }
    .no-selection {
      opacity: 0.3;
      font-size: 0.8rem;
      margin: 0;
    }
  }
  .payment {
    display: grid;
    place-content: center;
    .no-payment {
      opacity: 0.3;
      font-size: 0.8rem;
      margin: 0;
    }
  }
  .actions {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-left: 20px;
    border-left: 1px solid #e6e6e6;
  }
  .not-seen {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #ff9900;
  }
`
const ConfirmContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  .avatar {
    margin-bottom: 16px;
  }
  .message {
    font-size: 1rem;
    margin-bottom: 10px;
  }
`
