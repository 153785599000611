import { HeartFilled } from '@ant-design/icons'
import { Alert, Button, message } from 'antd'
import axios from 'axios'
import { Formik, Form } from 'formik'
import { Rate } from 'formik-antd'
import React from 'react'
import { queryCache } from 'react-query'
import styled from 'styled-components'
import * as yup from 'yup'

import { NODE_URL } from '../../../../constants'
import FormItem from '../../../forms/FormItem'

export const OptInRating = ({ optIn, brandId }) => {
  const initialValues = {
    content: optIn.rating?.content,
    creativity: optIn.rating?.creativity,
    communication: optIn.rating?.communication,
    overall: optIn.rating?.overall,
  }

  const validationSchema = yup.object().shape({
    content: yup.number().required('Required'),
    creativity: yup.number().required('Required'),
    communication: yup.number().required('Required'),
    overall: yup.number().required('Required'),
  })

  const handleSubmit = async values => {
    const data = {
      rating: {
        content: values.content,
        creativity: values.creativity,
        communication: values.communication,
        overall: values.overall,
      },
    }
    try {
      await axios.post(`${NODE_URL}/brand/${brandId}/opt-in/${optIn.id}/rating`, data)
      await queryCache.invalidateQueries('opt-ins')
      await queryCache.invalidateQueries(['average-rating', optIn.user.id])
      await queryCache.invalidateQueries(['campaign-history', optIn.user.id])
      message.success('Rating submitted')
    } catch (err) {
      console.log(err)
      message.error(err?.response?.data?.err || 'Sorry, something went wrong.')
    }
  }

  return (
    <Wrapper>
      <Alert
        message={
          optIn.rating
            ? `Thank you for rating ${optIn.user.firstName}'s performance!`
            : `Show everyone how ${optIn.user.firstName} did by rating their performance in your campaign!`
        }
        type={optIn.rating ? 'success' : 'info'}
        style={{ marginBottom: '20px' }}
        showIcon
      />

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        validateOnBlur={false}>
        {({ submitForm, isSubmitting }) => (
          <Form>
            <FormItem
              name='content'
              label='Content Quality: '
              sublabel={`How would you rate the overall quality of the influencer's content?`}
              size='small'>
              <Rate character={<HeartFilled />} name='content' />
            </FormItem>

            <FormItem
              name='creativity'
              label='Creativity: '
              sublabel={`How would you rate the creativity of the influencer's approach to your campaign?`}
              size='small'>
              <Rate character={<HeartFilled />} name='creativity' />
            </FormItem>

            <FormItem
              name='communication'
              label='Communication: '
              sublabel={`How effective was the influencer's communication throughout the partnership?`}
              size='small'>
              <Rate character={<HeartFilled />} name='communication' />
            </FormItem>

            <FormItem
              name='overall'
              label='Overall Satisfaction: '
              sublabel={`How satisfied are you with the partnership with the influencer?`}
              size='small'>
              <Rate character={<HeartFilled />} name='overall' />
            </FormItem>

            <Button onClick={submitForm} loading={isSubmitting} type='primary'>
              {optIn.rating ? 'Update' : 'Submit'} Rating
            </Button>
          </Form>
        )}
      </Formik>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: auto;
    max-width: 400px;

    button {
      margin: auto;
    }
  }

  .ant-rate {
    background: #fff;
    color: ${props => props.theme.crcoCoral};
    padding: 5px 10px 0 10px;
    font-size: 1.5rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`
