import {
  UploadOutlined,
  ExclamationCircleOutlined,
  HourglassOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import { Button, message, Result, Modal, Drawer, Checkbox, Alert } from 'antd'
import axios from 'axios'
import React, { useContext, useState, useEffect, useRef } from 'react'
import { queryCache, useQuery } from 'react-query'
import { useParams, Redirect, useHistory, Link } from 'react-router-dom'
import { useSpring, animated } from 'react-spring'
import styled from 'styled-components'

import { AutomaticMessages } from './AutomaticMessages'
import { CampaignBuilderGuide } from './CampaignBuilderGuide'
import { CampaignLogistics } from './CampaignLogistics'
import { CampaignMetrics } from './CampaignMetrics'
import { CampaignPreview } from './CampaignPreview'
import { CampaignStrategy } from './CampaignStrategy'
import { GuideInfluencers } from './GuideInfluencers'
import { InfluencerDescription } from './InfluencerDescription'
import { FadeIn } from '../../components/general/FadeIn'
import LoadingLogo from '../../components/general/LoadingLogo'
import { SavingIndicator } from '../../components/general/SavingIndicator'
import { NODE_URL } from '../../constants'
import { BrandContext } from '../../contexts/BrandContext'
import { CampaignContext } from '../../contexts/CampaignContext'

const EditCampaign = () => {
  const history = useHistory()
  const { brandId, fetchBrandData } = useContext(BrandContext)
  const [submitModalVisible, setSubmitModalVisible] = useState(false)
  const [formData, setFormData] = useState(undefined)

  const { data: brandData, status: brandDataStatus } = useQuery(['brand', brandId], fetchBrandData)

  const handleOk = () => {
    setSubmitModalVisible(false)
    history.push('/campaigns')
  }

  const handleCancel = () => {
    setSubmitModalVisible(false)
  }

  return (
    <>
      {brandDataStatus === 'success' && (
        <>
          <Container
            formData={formData}
            setFormData={setFormData}
            setSubmitModalVisible={setSubmitModalVisible}
            brandData={brandData}
          />
          <Modal
            open={submitModalVisible}
            title='Campaign Submitted'
            okText='Got it!'
            onOk={handleOk}
            onCancel={handleCancel}
            // hide cancel button
            footer={[
              <Button key='submit' type='primary' onClick={handleOk}>
                Got it!
              </Button>,
            ]}>
            <ModalInner>
              {!brandData.userHasAgencyPrivileges ? (
                <>
                  <p>
                    Campaign submitted! Now use the{' '}
                    <Link to='/creator-search'>Creator Recruitment</Link> tool to curate a list of
                    Creators to be invited to your new campaign.
                  </p>
                  <p>
                    Please allow up to 2 business days for our team to review your campaign.
                    You&apos;ll receive an email with any clarifying questions or suggestions before
                    going live.
                  </p>
                  <p>
                    Don&apos;t forget to check your spam folder and ensure we&apos;re marked as a
                    trusted sender.
                  </p>
                </>
              ) : (
                <p>
                  Your campaign has been automatically published! Now use the{' '}
                  <Link to='/creator-list'>Creator Recruitment</Link> tool to curate and invite
                  Creators to your campaign. If you experience any issues, please contact our
                  support team.
                </p>
              )}
            </ModalInner>
          </Modal>
        </>
      )}
    </>
  )
}

const ModalInner = styled.div`
  .header {
    font-family: 'Campton-Medium';
    font-size: 1.2rem;
    text-align: center;
    margin: 20px 0;
  }
  p {
    margin: 10px;
    font-size: 14px;
    color: ${props => props.theme.crcoGrey};
  }
  .button {
    display: flex;
    justify-content: center;
  }
  .buttons {
    display: flex;
    justify-content: center;
    button {
      margin: 0 10px;
      border: 2px solid ${props => props.theme.crcoTechBlue};
      font-family: 'Campton-Medium';
      .anticon {
        margin-right: 10px;
      }
      &.ant-btn-secondary {
        color: ${props => props.theme.crcoTechBlue};
        a {
          color: ${props => props.theme.crcoTechBlue};
        }
      }
      &:hover {
        background: ${props => props.theme.crcoMidnight};
        color: #fff;
        border: 2px solid ${props => props.theme.crcoMidnight};
        a {
          color: #fff;
        }
      }
      &:disabled {
        pointer-events: none;
        opacity: 0.8;
        color: #999;
        border: 2px solid #999;
        &.ant-btn-primary {
          background: #999;
          color: #fff;
        }
      }
    }
  }
  .ant-modal-footer {
    display: none;
  }
`

const Container = ({ formData, setFormData, setSubmitModalVisible, brandData }) => {
  const { campaignId } = useParams()
  const [scroll, setScroll] = useSpring(() => ({ scroll: 0, config: { mass: 0.8, tension: 200 } }))

  // #region Contexts
  const { fetchCampaign } = useContext(CampaignContext)
  // #endregion Contexts

  // #region Refs
  const submitRef = useRef(0)
  const form1Ref = useRef(false) // campaign strategy
  const form2Ref = useRef(false) // campaign logistics
  const form3Ref = useRef(false) // influencer description
  const form4Ref = useRef(false) // guide influencer
  const form5Ref = useRef(false) // automatic messages
  const form6Ref = useRef(false) // campaign metrics
  const contentRef = useRef()
  const strategyRef = useRef()
  const campaignDetailsRef = useRef()
  const creatorDetailsRef = useRef()
  const contentDetailsRef = useRef()
  const automaticMessagesRef = useRef()
  const metricsDetailsRef = useRef()
  // #endregion Refs

  // #region States
  const [savingStatus, setSavingStatus] = useState(undefined)
  const [submitting, setSubmitting] = useState(false)
  const [guideOpen, setGuideOpen] = useState(false)
  const [publishModalVisible, setPublishModalVisible] = useState(false)
  const [skipSteps, setSkipSteps] = useState(false)

  // set active form nav item on scroll
  const [currentSection, setCurrentSection] = useState(1)
  // #endregion States

  // #region Queries
  const { data: campaign, status } = useQuery(['campaign', campaignId], fetchCampaign)
  // #endregion Queries

  // #region Functions
  useEffect(() => {
    // set initial form data to existing campaign if found
    if (campaign) {
      const {
        metaData,
        holdCost,
        maxPaidAmount,
        minPaidAmount,
        categories,
        contentFormats,
        countries,
        brandAffiliateLinkIds,
        ...data
      } = campaign

      setFormData({
        ...data,
        ...metaData,

        unlisted: !!data.password,
        variables: campaign.variables || [],

        minValue: metaData?.minValue,
        affiliateLinks: metaData?.affiliateLinks || [{ url: '', description: '' }],
        brandAffiliateLinkIds: brandAffiliateLinkIds || [],
        rakutenAffiliateLinks: metaData?.rakutenAffiliateLinks || [{ url: '', description: '' }],
        shareasaleAffiliateLinks: metaData?.shareasaleAffiliateLinks || [
          { url: '', description: '' },
        ],
        impactRadiusAffiliateLinks: metaData?.impactRadiusAffiliateLinks || [
          { url: '', description: '' },
        ],
        cjAffiliateLinks: metaData?.cjAffiliateLinks || [{ linkId: '', url: '', description: '' }],

        // ROI Tracking
        likeValue: metaData?.likeValue || 0.1,
        commentValue: metaData?.commentValue || 0.1,
        viewValue: metaData?.viewValue || 0.05,
        saveValue: metaData?.saveValue || 5.0,
        shareValue: metaData?.shareValue || 5.0,
        reachValue: metaData?.reachValue || 0.05,
        impressionValue: metaData?.impressionValue || 0.05,

        // other data
        optInMessageTemplate: metaData.optInMessageTemplate,
        activationMessageTemplate: metaData.activationMessageTemplate,
        cancellationMessageTemplate: metaData.cancellationMessageTemplate,
        completionMessageTemplate: metaData.completionMessageTemplate,
        interests: categories?.map(category => category.category.slug) || [],
        securityHold: holdCost ? true : false,
        maxShopifyProducts: metaData?.maxShopifyProducts || 1,
        shopifyPriceRuleId: metaData?.shopifyPriceRuleId,
        paidCampaign: maxPaidAmount || minPaidAmount ? true : false,
        paymentType: maxPaidAmount || minPaidAmount ? (!minPaidAmount ? 'fixed' : 'ranged') : '',
        minPaidAmount,
        maxPaidAmount,
        countries:
          countries?.map(country => {
            return {
              value: country.country.countryCode,
              valueText: country.country.countryCode,
            }
          }) || [],
        contentFormats: contentFormats?.map(format => format) || [],
        contentFormatCounts: metaData?.contentFormatCounts || {
          ...contentFormats?.reduce((acc, format) => {
            acc[format.toLowerCase()] = 1
            return acc
          }, {}),
        },
      })
    }
  }, [campaign, setFormData])

  const submitCampaign = async review => {
    // Checking if this is a review/publish submission or just an auto-save
    if (!review) {
      // If campaign is live, don't auto-save changes
      if (campaign.status == 'publish') {
        return
      } else {
        setSavingStatus('submitting')
      }
    }

    submitRef.current++
    const thisSubmit = submitRef.current
    setTimeout(async () => {
      if (thisSubmit === submitRef.current) {
        if (review) {
          // submit forms to trigger validation if campaign is being submitted for review
          if (form1Ref.current) {
            form1Ref.current.handleSubmit()
          }
          if (form2Ref.current) {
            form2Ref.current.handleSubmit()
          }
          if (form3Ref.current) {
            form3Ref.current.handleSubmit()
          }
          if (form4Ref.current) {
            form4Ref.current.handleSubmit()
          }
          if (form5Ref.current) {
            form5Ref.current.handleSubmit()
          }
          if (form6Ref.current) {
            form6Ref.current.handleSubmit()
          }
        }

        // not the best solution but for some reason formRefs take a moment (<1 ms?) to update so we wait for that
        await new Promise(resolve => setTimeout(resolve, 1))

        // checking for errors (validation) in each form section
        const errors = {
          ...(form1Ref?.current?.errors || {}),
          ...(form2Ref?.current?.errors || {}),
          ...(form3Ref?.current?.errors || {}),
          ...(form4Ref?.current?.errors || {}),
          ...(form5Ref?.current?.errors || {}),
          ...(form6Ref?.current?.errors || {}),
        }

        let err
        err = Object.keys(errors)?.length

        if (review && err) {
          message.warning('Please fill out all required fields')
          // scroll to first form with error
          // TODO: could be improved (scroll to form item with error) - use ScrollToFieldError component within formik form or get element by id/name attribute and scroll to element (react makes it difficult to get element by id/name though)
          if (Object.keys(form1Ref?.current?.errors).length) {
            // scroll to campaign basics (top)
            setScroll({
              to: { scroll: 0 },
              from: { scroll: contentRef.current?.scrollTop },
              reset: true,
            })
          } else if (Object.keys(form2Ref?.current?.errors).length) {
            // scroll to campaign details
            setScroll({
              to: { scroll: campaignDetailsRef.current?.offsetTop - 80 },
              from: { scroll: contentRef.current?.scrollTop },
              reset: true,
            })
          } else if (Object.keys(form3Ref?.current?.errors).length) {
            // scroll to creator qualifications
            setScroll({
              to: { scroll: creatorDetailsRef.current?.offsetTop - 80 },
              from: { scroll: contentRef.current?.scrollTop },
              reset: true,
            })
          } else if (Object.keys(form4Ref?.current?.errors).length) {
            // scroll to deliverables
            setScroll({
              to: { scroll: contentDetailsRef.current?.offsetTop - 80 },
              from: { scroll: contentRef.current?.scrollTop },
              reset: true,
            })
          } else if (Object.keys(form5Ref?.current?.errors).length) {
            // scroll to automatic messages
            setScroll({
              to: { scroll: automaticMessagesRef.current?.offsetTop - 80 },
              from: { scroll: contentRef.current?.scrollTop },
              reset: true,
            })
          } else if (Object.keys(form6Ref?.current?.errors).length) {
            // scroll to campaign metrics
            setScroll({
              to: { scroll: metricsDetailsRef.current?.offsetTop - 80 },
              from: { scroll: contentRef.current?.scrollTop },
              reset: true,
            })
          }
          return
        }

        // submit data if there are no form errors or campaign is being saved as draft
        if ((review && !err) || !review) {
          const form1Vals = form1Ref.current?.values || ''
          const form2Vals = form2Ref.current?.values || ''
          const form3Vals = form3Ref.current?.values || ''
          const form4Vals = form4Ref.current?.values || ''
          const form5Vals = form5Ref.current?.values || ''
          const form6Vals = form6Ref.current?.values || ''

          const data = {
            brandId: brandData.id,
            status: review ? 'pending' : formData.status,
            ...formData,
            ...form1Vals,
            ...form2Vals,
            ...form3Vals,
            ...form4Vals,
            ...form5Vals,
            ...form6Vals,
          }

          try {
            // prevent submitting campaign for review if brand has no active subscriptions
            if (review && brandData?.activeSubscriptions?.length === 0) return
            if (review) setSubmitting(true)
            await axios.post(`${NODE_URL}/brand-dashboard/campaigns/${campaign.id}`, {
              ...data,
              originalCampaignFields: {
                countries: campaign.countries,
                interests: campaign.categories,
                productListId: campaign.productListId,
                brandAffiliateLinkIds: campaign.brandAffiliateLinkIds,
              },
              review,
            })
            if (review) {
              setSubmitModalVisible(true)
              setPublishModalVisible(false)
            } else {
              setSavingStatus('success')
              setTimeout(() => {
                setSavingStatus(undefined)
              }, 2000)
            }
            setSubmitting(false)
            // refetch campaign to update status
            queryCache.invalidateQueries('campaign')
          } catch (err) {
            message.error('Sorry, something went wrong.')
            setSavingStatus('error')
            setTimeout(() => {
              setSavingStatus(undefined)
            }, 2000)
          }
        }
      }
    }, 300)
  }

  const handleScroll = e => {
    // FIXME: this gets confused when scrolling within text areas
    const { scrollTop } = e.target

    if (scrollTop < campaignDetailsRef?.current?.offsetTop - 80) {
      setCurrentSection(1)
    } else if (scrollTop < creatorDetailsRef?.current?.offsetTop - 80) {
      setCurrentSection(2)
    } else if (scrollTop < contentDetailsRef?.current?.offsetTop - 80) {
      setCurrentSection(3)
    } else if (scrollTop < automaticMessagesRef?.current?.offsetTop - 80) {
      setCurrentSection(4)
    } else setCurrentSection(5)
  }
  // #endregion Functions

  // redirects to campaign dashboard if this campaign doesn't belong to current brand or if campaign is live or paused
  const redirect =
    (campaign && campaign?.brand?.id !== brandData.id) ||
    ((campaign?.status === 'publish' || campaign?.status === 'paused') &&
      !brandData.userHasAgencyPrivileges)
  return redirect ? (
    <Redirect to='/campaigns' />
  ) : (
    <Wrapper>
      {status === 'success' && formData && (
        <>
          <div className='campaign-builder-wrapper'>
            <animated.div
              className='form-sections'
              scrollTop={scroll.scroll}
              ref={contentRef}
              onScroll={handleScroll}>
              {!brandData.activeSubscriptions?.length && (
                <Alert
                  type='warning'
                  message='You must be on a subscription plan to submit campaigns.'
                  showIcon
                  style={{ marginBottom: '20px' }}
                />
              )}
              {formData?.status === 'publish' && (
                <Alert
                  type='warning'
                  message={`This campaign is already live. In order to update any details, please confirm your changes and click "Publish Campaign".`}
                  showIcon
                  style={{ marginBottom: '20px' }}
                />
              )}
              <div className='banner'>
                <div className='header-text'>
                  <h1>Campaign Builder</h1>
                </div>
                <Button
                  type='link'
                  icon={<QuestionCircleOutlined />}
                  onClick={() => setGuideOpen(true)}>
                  Help
                </Button>
                <Drawer
                  title='Campaign Builder Guide'
                  open={guideOpen}
                  onClose={() => setGuideOpen(false)}>
                  <CampaignBuilderGuide />
                </Drawer>
              </div>
              <FadeIn>
                <CampaignStrategy
                  formData={formData}
                  setFormData={setFormData}
                  formRef={form1Ref}
                  sectionRef={strategyRef}
                  submitCampaign={submitCampaign}
                />
              </FadeIn>

              {formData.strategy &&
                (!!formData.socialChannels?.length ||
                  formData.strategy === 'ugc' ||
                  formData.strategy === 'affiliate') && (
                  <>
                    <FadeIn>
                      <CampaignLogistics
                        formData={formData}
                        setFormData={setFormData}
                        formRef={form2Ref}
                        sectionRef={campaignDetailsRef}
                        submitCampaign={submitCampaign}
                      />
                    </FadeIn>
                    <FadeIn>
                      <InfluencerDescription
                        formData={formData}
                        setFormData={setFormData}
                        formRef={form3Ref}
                        sectionRef={creatorDetailsRef}
                        submitCampaign={submitCampaign}
                      />
                    </FadeIn>
                    <FadeIn>
                      <GuideInfluencers
                        formData={formData}
                        setFormData={setFormData}
                        formRef={form4Ref}
                        sectionRef={contentDetailsRef}
                        submitCampaign={submitCampaign}
                      />
                    </FadeIn>
                    <FadeIn>
                      <AutomaticMessages
                        formData={formData}
                        setFormData={setFormData}
                        formRef={form5Ref}
                        sectionRef={automaticMessagesRef}
                        submitCampaign={submitCampaign}
                      />
                    </FadeIn>
                    {formData.strategy !== 'ugc' && (
                      <FadeIn>
                        <CampaignMetrics
                          formData={formData}
                          setFormData={setFormData}
                          formRef={form6Ref}
                          sectionRef={metricsDetailsRef}
                          submitCampaign={submitCampaign}
                        />
                      </FadeIn>
                    )}
                  </>
                )}
            </animated.div>

            <aside className='campaign-builder-sidebar'>
              <div className='form-header'>
                <Button
                  loading={submitting}
                  type='primary'
                  disabled={!formData.title || !formData.strategy}
                  onClick={() => {
                    if (brandData.userHasAgencyPrivileges) {
                      setPublishModalVisible(true)
                      return
                    }
                    if (formData.status !== 'draft') return
                    submitCampaign(true)
                  }}
                  icon={formData.status === 'draft' ? <UploadOutlined /> : <HourglassOutlined />}>
                  {brandData?.userHasAgencyPrivileges
                    ? 'Publish Campaign'
                    : formData.status === 'draft'
                      ? 'Submit for Review'
                      : 'In Review'}
                </Button>
                <SavingIndicator savingStatus={savingStatus} label />
              </div>
              <div className='form-nav'>
                <FadeIn>
                  <div
                    className={`item ${currentSection === 1 && 'active'} ${
                      form1Ref.current && Object.keys(form1Ref.current?.errors).length && 'error'
                    }`}
                    onClick={() => {
                      setScroll({
                        to: { scroll: 0 },
                        from: { scroll: contentRef.current?.scrollTop },
                        reset: true,
                      })
                    }}
                    onKeyDown={() => {
                      setScroll({
                        to: { scroll: 0 },
                        from: { scroll: contentRef.current?.scrollTop },
                        reset: true,
                      })
                    }}
                    role='button'
                    tabIndex={0}>
                    General
                    {form1Ref.current && Object.keys(form1Ref.current?.errors).length > 0 && (
                      <ExclamationCircleOutlined />
                    )}
                  </div>
                </FadeIn>
                {formData.strategy &&
                  (!!formData.socialChannels?.length ||
                    formData.strategy === 'ugc' ||
                    formData.strategy === 'affiliate') && (
                    <>
                      <FadeIn>
                        <div
                          className={`item ${currentSection === 2 && 'active'}
                        ${
                          form2Ref.current && Object.keys(form2Ref.current?.errors).length
                            ? 'error'
                            : ''
                        }`}
                          onClick={() => {
                            setScroll({
                              to: { scroll: campaignDetailsRef.current?.offsetTop - 80 },
                              from: { scroll: contentRef.current?.scrollTop },
                              reset: true,
                            })
                          }}
                          onKeyDown={() => {
                            setScroll({
                              to: { scroll: campaignDetailsRef.current?.offsetTop - 80 },
                              from: { scroll: contentRef.current?.scrollTop },
                              reset: true,
                            })
                          }}
                          role='button'
                          tabIndex={0}>
                          Compensation
                          {form2Ref.current && Object.keys(form2Ref.current?.errors).length > 0 && (
                            <ExclamationCircleOutlined />
                          )}
                        </div>
                        <div
                          className={`item ${currentSection === 3 && 'active'}
                        ${
                          form3Ref.current && Object.keys(form3Ref.current?.errors).length > 0
                            ? 'error'
                            : ''
                        }`}
                          onClick={() => {
                            setScroll({
                              to: { scroll: creatorDetailsRef.current?.offsetTop - 80 },
                              from: { scroll: contentRef.current?.scrollTop },
                              reset: true,
                            })
                          }}
                          onKeyDown={() => {
                            setScroll({
                              to: { scroll: creatorDetailsRef.current?.offsetTop - 80 },
                              from: { scroll: contentRef.current?.scrollTop },
                              reset: true,
                            })
                          }}
                          role='button'
                          tabIndex={0}>
                          Requirements
                          {form3Ref.current && Object.keys(form3Ref.current?.errors).length > 0 && (
                            <ExclamationCircleOutlined />
                          )}
                        </div>
                        <div
                          className={`item ${currentSection === 4 && 'active'}
                        ${
                          form4Ref.current && Object.keys(form4Ref.current?.errors).length > 0
                            ? 'error'
                            : ''
                        }`}
                          onClick={() => {
                            setScroll({
                              to: { scroll: contentDetailsRef.current?.offsetTop - 80 },
                              from: { scroll: contentRef.current?.scrollTop },
                              reset: true,
                            })
                          }}
                          onKeyDown={() => {
                            setScroll({
                              to: { scroll: contentDetailsRef.current?.offsetTop - 80 },
                              from: { scroll: contentRef.current?.scrollTop },
                              reset: true,
                            })
                          }}
                          role='button'
                          tabIndex={0}>
                          Deliverables
                          {form4Ref.current && Object.keys(form4Ref.current?.errors).length > 0 && (
                            <ExclamationCircleOutlined />
                          )}
                        </div>
                        <div
                          className={`item ${currentSection === 5 && 'active'}
                        ${
                          form5Ref.current && Object.keys(form5Ref.current?.errors).length > 0
                            ? 'error'
                            : ''
                        }`}
                          onClick={() => {
                            setScroll({
                              to: { scroll: automaticMessagesRef.current?.offsetTop - 80 },
                              from: { scroll: contentRef.current?.scrollTop },
                              reset: true,
                            })
                          }}
                          onKeyDown={() => {
                            setScroll({
                              to: { scroll: automaticMessagesRef.current?.offsetTop - 80 },
                              from: { scroll: contentRef.current?.scrollTop },
                              reset: true,
                            })
                          }}
                          role='button'
                          tabIndex={0}>
                          Automatic Messages
                          {form5Ref.current && Object.keys(form5Ref.current?.errors).length > 0 && (
                            <ExclamationCircleOutlined />
                          )}
                        </div>
                        {formData.strategy !== 'ugc' && (
                          <div
                            className={`item ${currentSection === 6 && 'active'}
                        ${
                          form6Ref.current && Object.keys(form6Ref.current?.errors).length > 0
                            ? 'error'
                            : ''
                        }`}
                            onClick={() => {
                              setScroll({
                                to: { scroll: metricsDetailsRef.current?.offsetTop - 80 },
                                from: { scroll: contentRef.current?.scrollTop },
                                reset: true,
                              })
                            }}
                            onKeyDown={() => {
                              setScroll({
                                to: { scroll: metricsDetailsRef.current?.offsetTop - 80 },
                                from: { scroll: contentRef.current?.scrollTop },
                                reset: true,
                              })
                            }}
                            role='button'
                            tabIndex={0}>
                            Metrics
                            {form6Ref.current &&
                              Object.keys(form6Ref.current?.errors).length > 0 && (
                                <ExclamationCircleOutlined />
                              )}
                          </div>
                        )}
                      </FadeIn>
                    </>
                  )}
              </div>
              <FadeIn>
                <div className='campaign-preview'>
                  <CampaignPreview formData={formData} />
                </div>
              </FadeIn>
            </aside>
          </div>
          <Modal
            open={publishModalVisible}
            title='Publish Campaign'
            okText='Publish'
            onOk={() => submitCampaign(true)}
            okButtonProps={{ disabled: !formData.steps?.length && !skipSteps, loading: submitting }}
            onCancel={() => setPublishModalVisible(false)}>
            <div>
              {!formData?.steps?.length && (
                <>
                  <Alert
                    type='warning'
                    message='Are you sure you want to launch this campaign without steps?'
                    showIcon
                    style={{ marginBottom: '10px' }}
                  />
                  <Checkbox
                    onChange={e => setSkipSteps(e.target.checked)}
                    style={{ marginBottom: '20px' }}>
                    Skip steps (not recommended)
                  </Checkbox>
                </>
              )}
              <p>
                By clicking publish, you agree to our{' '}
                <a href='https://creator.co/brand-terms/' target='_blank' rel='noreferrer noopener'>
                  Terms & Conditions
                </a>
                .
              </p>
            </div>
          </Modal>
        </>
      )}
      {status === 'loading' && (
        <div className='loading-wrapper'>
          <LoadingLogo />
        </div>
      )}
      {status === 'error' && (
        <div className='error-wrapper'>
          <Result status='404' title='404' subTitle='Campaign not found.' />
          <Button type='primary'>
            <Link to='/campaigns'>Back to Campaigns</Link>
          </Button>
        </div>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  height: 100%;
  overflow: hidden;
  .loading-wrapper,
  .error-wrapper {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .campaign-builder-wrapper {
    background: #fff;
    color: #23262f;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
  }
  .inner {
    height: 100vh;
    overflow: auto;
    position: relative;
  }
  .campaign-builder-sidebar {
    display: flex;
    flex-direction: column;
    overflow: auto;
    ${props => props.theme.scrollbar};
    .form-nav {
      margin: 20px 20px 20px 0;
      display: flex;
      flex-direction: column;
    }
    .item {
      border-radius: 0 15px 15px 0;
      padding: 5px 30px;
      font-size: 1rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      transition: 0.2s ease-in-out;
      border-left: 2px solid transparent;
      margin-bottom: 10px;
      &:hover {
        background-color: #f6f6f6;
        border-color: #ccc;
      }
      &.active {
        background-color: #ecf6ff;
        border-color: ${props => props.theme.crcoTechBlue};
      }
      &.error {
        color: red;
      }
    }
    .campaign-preview {
      padding: 0 20px 20px 20px;
    }
  }
  .strategy-table {
    margin-bottom: 32px;
    border: 1px solid #e6e6e6;
    tr {
      border-bottom: 1px solid #e6e6e6;
      &:last-child {
        border-bottom: none;
      }
    }
    .strategy {
      font-family: 'Campton-Medium', Verdana, sans-serif;
      padding: 5px 10px;
      border-right: 1px solid #e6e6e6;
      width: 150px;
    }
    .description {
      font-family: 'Campton-Light', Verdana, sans-serif;
      padding: 5px 10px;
    }
  }
  .form-header {
    background: #fff;
    width: 100%;
    border-bottom: 1px solid #e6e6e6;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px;
    button {
      &.ant-btn-primary {
        &:hover {
          background: ${props => props.theme.crcoMidnight};
          border-color: ${props => props.theme.crcoMidnight};
          color: #fff;
        }
      }
      &:disabled {
        pointer-events: none;
        opacity: 0.8;
        color: #999;
        border: 1px solid #999;
        &.ant-btn-primary {
          background: #999;
          color: #fff;
        }
      }
    }
  }
  .form-sections {
    background: #f4f4f8;
    flex: 1;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e6e6e6;
    overflow: auto;
    padding: 20px;
    ${props => props.theme.scrollbar};
    section {
      background: #fff;
      max-width: 700px;
      margin: 0 auto;
      margin-bottom: 20px;
      padding: 20px;
      border-radius: 10px;
    }
  }
  .banner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    margin: 0 auto 20px auto;
    width: 100%;
    max-width: 700px;
    .header-text {
      display: flex;
      align-items: center;
      gap: 12px;
    }
    .platform-icon {
      margin-left: 10px;
      height: 20px;
    }
  }
  h1 {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    color: #23262f;
    font-size: 1.5rem;
    margin: 0;
  }
  .header {
    font-family: 'Gilroy-ExtraBold', sans-serif;
    color: #23262f;
    font-size: 1.4rem;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #e6e6e6;
    &.error {
      color: red;
    }
  }
  h3 {
    font-size: 1.2rem;
    margin-top: 20px;
  }
  .header-subtext {
    font-size: 1rem;
    color: ${props => props.theme.crcoGrey};
  }
  .sublabel {
    span {
      display: block;
      border: 1px solid #000;
    }
  }
  .form-row {
    margin: 0;
    display: flex;
    gap: 12px;
  }
  .ant-affix {
    padding: 0 10px;
  }
  .ant-input-disabled {
    background: #f2f2f2;
    border: 1px solid #e6e6e6;
  }
  .paid-campaign-details,
  .custom-fulfillment {
    background: #f6f6f6;
    border-radius: 5px;
    padding: 20px;
    margin-bottom: 32px;
    &:last-child,
    div {
      margin-bottom: 0;
    }
  }
  .ant-tag {
    margin: 2px;
  }
  .social-icon {
    height: 20px;
    width: 20px;
    &.not-available {
      opacity: 0.3;
      filter: grayscale(1);
    }
  }
`

export default EditCampaign
