import { Drawer, Spin } from 'antd'
import React, { useContext, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import NewOptInItem from './NewOptInItem'
import NewShoutoutItem from './NewShoutoutItem'
import { CampaignContext } from '../../../../contexts/CampaignContext'
import { InfluencerContextProvider } from '../../../../contexts/InfluencerContext'
import { OptInContextProvider } from '../../../../contexts/OptInContext'
import { CreatorProfileActions } from '../../influencer-profile/CreatorProfileActions'
import OptInProfile from '../../influencer-profile/OptInProfile'

const Overview = ({ campaign }) => {
  const { selectedCampaignID, fetchUnseenOptIns, fetchShoutouts } = useContext(CampaignContext)

  // opt-ins
  const { data: unseenOptIns, status: unseenOptInsStatus } = useQuery(
    ['unseen-opt-ins', selectedCampaignID],
    fetchUnseenOptIns,
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )

  // shoutouts
  // get date 30 days ago
  const thirtyDaysAgo = new Date()
  thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
  const { data: unseenShoutouts, status: unseenShoutoutsStatus } = useQuery(
    [
      'shoutouts',
      {
        campaignID: selectedCampaignID,
        formData: { fromDate: thirtyDaysAgo.toISOString().split('T')[0] },
      },
    ],
    fetchShoutouts,
    {
      refetchInterval: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  )

  const [profileOpen, setProfileOpen] = useState(false)
  const [selectedOptIn, setSelectedOptIn] = useState(false)

  const setSelectedOptIns = id => {
    for (let i = 0; i < unseenOptIns.optIns.length; i++) {
      const optIn = unseenOptIns.optIns[i]

      if (optIn.id !== id) continue

      setSelectedOptIn({
        ...optIn,
        next: unseenOptIns.optIns[i + 1]?.id || false,
        prev: unseenOptIns.optIns[i - 1]?.id || false,
      })
    }
  }

  return (
    <Wrapper>
      <section>
        <div className='title'>New Opt-Ins</div>
        <div className='inner new-opt-ins'>
          {unseenOptInsStatus === 'success' ? (
            unseenOptIns.totalResults ? (
              unseenOptIns.optIns.map(optIn => (
                <NewOptInItem
                  optIn={optIn}
                  key={optIn.id}
                  selectedOptIn={selectedOptIn}
                  setSelectedOptIn={setSelectedOptIns}
                  setShowProfile={setProfileOpen}
                />
              ))
            ) : (
              <div className='no-results'>None.</div>
            )
          ) : (
            <div className='loading'>
              <Spin size='small' />
            </div>
          )}
          <OptInContextProvider>
            <InfluencerContextProvider>
              <Drawer
                title={`
                ${selectedOptIn?.status?.charAt(0).toUpperCase() + selectedOptIn?.status?.slice(1)}
                Opt-In: ${selectedOptIn?.user?.firstName} ${selectedOptIn?.user?.lastName}`}
                width={window.innerWidth > 1200 ? 1200 : window.innerWidth}
                closable={true}
                onClose={() => {
                  setProfileOpen(false)
                  setSelectedOptIn(false)
                }}
                destroyOnClose={true}
                open={profileOpen}
                extra={
                  <CreatorProfileActions
                    optIn={selectedOptIn}
                    setSelectedOptIn={setSelectedOptIns}
                  />
                }>
                {selectedOptIn && (
                  <OptInProfile
                    key={selectedOptIn.id}
                    optIn={selectedOptIn}
                    campaign={campaign}
                    setSelectedOptIn={setSelectedOptIns}
                    close={() => {
                      setProfileOpen(false)
                      setSelectedOptIn(false)
                    }}
                  />
                )}
              </Drawer>
            </InfluencerContextProvider>
          </OptInContextProvider>
        </div>
      </section>
      <section>
        <div className='title'>Recent ShoutOuts</div>
        <div className='inner new-shoutouts'>
          {unseenShoutoutsStatus === 'success' ? (
            unseenShoutouts.totalResults ? (
              unseenShoutouts?.socialPosts.map(socialPost => (
                <NewShoutoutItem key={socialPost.id} socialPost={socialPost} />
              ))
            ) : (
              <div className='no-results'>No ShoutOuts in the last 30 days</div>
            )
          ) : (
            <div className='loading'>
              <Spin size='small' />
            </div>
          )}
        </div>
      </section>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 100%;
  padding: 24px;

  section {
    margin-bottom: 24px;
    .title {
      font-size: 1.3rem;
      margin-bottom: 8px;
      font-weight: bold;
    }
  }

  .new-opt-ins,
  .new-shoutouts {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 12px;
  }

  .no-results {
    color: #999;
  }

  .notifications {
    max-height: 370px;
    overflow-y: auto;
    ${props => props.theme.scrollbar}
  }
`

export default Overview
